/***
 **Module Name: category
 **File Name :  category.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains category page details.
 ***/
import React, { useState, useEffect, useRef } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router";
import Footer from "../components/footer/Footer";
import InsideHeader from "../components/InnerHeader/Header";
import $ from "jquery";
import Modal from 'react-bootstrap/Modal';

import { useForm, Controller } from "react-hook-form";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import * as Config from "../constants/Config";
import AdvanceSearch from "./advanceSearch";
import SweetAlert from 'react-bootstrap-sweetalert';

const Category = () => {
  const history = useHistory();
  const { control, handleSubmit } = useForm();
  const [menus, setMenus] = useState([]);
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [videoFormat, setVideoFormat] = useState([]);
  const [contentValue, setContentValue] = useState([]);
  const [success, setSuccess] = useState(false);
  const [searchTitleInput, SetSearchTitleInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [config, setConfig] = useState({});
  useEffect(() => {
      if (window.site) {
          setConfig(window.site);

      }

  }, [window.site]);

  if (config?.common && config?.common?.imagesCDN) {
     var img = config?.common?.imagesCDN;
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
    appMenus();
    Categories();
  }, []);

  const appMenus = async () => {
    try {
      console.log(tmdbApi);
      const response = await tmdbApi.appMenus({ result: "micro" });
      setMenus(response.result);
      console.log(response);
    } catch {
      console.log("error");
    }
  };

  const Categories = async () => {
    try {
      setIsLoading(true)
      // console.log('isLoading1', isLoading)
      const response = await tmdbApi.getCategories({});
      // console.log("response", response.result);
      if (response.statusCode === 200) {
        setData(response.result);
        setCategoryData(response.result)
        setIsLoading(false)
        // console.log('isLoading2', isLoading)
      }
    } catch {
      console.log("error");
    }
  };
  const filterCategoriesFun = async (categoryName) => {
    try {

      const payload = { 'category': [categoryName] }
      const response = await tmdbApi.filterCategory(payload);
      console.log("response", response.result);
      if (response.statusCode === 200) {
        // setData(response.result);
        console.log('category click on result', response.result)
        // setCategoryData(response.result)
      }
    } catch {
      console.log("error");
    }
  };

  const searchSubmit = async () => {
    try {
      const response = await tmdbApi.filterCategory({ name: searchTitleInput });
      if (response.statusCode === 200) {

        let data = response.result && response.result.data && response.result.data.length > 0 ? response.result.data : []
        // setInitialCategoriesData(data)
      }
    } catch {
      console.log("error");
    }

    try {
      const response = await tmdbApi.getCategories({});
      console.log("response", response.result);
      if (response.statusCode === 200) {
        setData(response.result);
        setCategoryData(response.result)
      }
    } catch {
      console.log("error");
    }

  }



  const handleCategory = (e, item) => {
    let categoryName = item.name
    // let signed = localStorage.getItem("signed");
    console.log('categoryName', categoryName)

    if (categoryName === "FORMATS") {
      let signed = localStorage.getItem("signed");
      console.log("signed", signed)
      if (signed === "false" || signed == undefined || signed == 'undefined') {
        console.log('signed', signed)
        setSuccess(true);
      }
      else {
        // history.push("/content/" + categoryName);
        // filterCategoriesFun(categoryName); 
      }
    } else {
      history.push("/content/" + categoryName);
      // filterCategoriesFun(categoryName); 
    }




  }

  function onConfirm() {
    setSuccess(false)
  };

  //const img = "https://orasi.imgix.net/orasi/client/resources/orasiv1/images/categories";
  const cardWidth = $(".col5-sm1").width();

  return (
    <>
      <Modal className="access-denied" show={success} >

        <div className="modal-body enquiry-form">
          <div className="container">
            <button className="close-btn" onClick={e => onConfirm()}><span className="material-icons">close</span></button>
            <span className="material-icons access-denied-icon">https</span>
            <h3>Access Denied</h3>
            <p>You are not eligible to view this content.</p>
            <p>Please contact administrator</p>
            <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>close</button>
          </div>
        </div>

      </Modal>
      <InsideHeader />

      <div className="inner-body">
        <div className="body-wrapper">
          <AdvanceSearch />

          <div className="main-block">
            <div className="block-header">
              <div className="block-title">
                <h2>Categories</h2>
                <ul className="bread-crumbs">

                  <Link to="/content"><li>Categories</li></Link>
                </ul>
              </div>

              <div className="search-block">
                <input
                  type="text"
                  className="searchinput"
                  placeholder="Search Content"
                  value={searchTitleInput} onChange={(e) => SetSearchTitleInput(e.target.value)}
                />
                <button className="fill_btn float-end" type='submit' onClick={searchSubmit}><span className="material-symbols-outlined">search</span></button>
              </div>

              <div className="body-actions">

              </div>
            </div>

            <div className="block-body">
              <div className="row">
                {data && data.length > 0 ? (<>
                  {data && data.length > 0 && data.map(function (item, i) {

                    return (
                      <div className="col5-sm1" key={i}>
                        <div className="category-thumb">
                          <div className="black-gradient thumb-titlewrapper">
                            <h6>{item.name}</h6>
                          </div>

                          <img src={item.image + "?auto=compress,format&width=" + cardWidth} onClick={(e) => handleCategory(e, item)} />

                        </div>
                      </div>
                    );
                  }

                  )


                  }</>) : isLoading ? (
                    <div className="orasi-preloader">
                      <img src={img+"orasi/common/images/preloader.png"} />
                    </div>
                  ) : null}


              </div>
            </div>
            <Footer menus={menus} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
