import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../pages/Home";

// import * as Config from "../constants/Config";
import SignUp from "../pages/signup";
import AboutUs from "../pages/AboutUs";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import ContactUs from "../pages/ContactUs";
import NotFound from "../pages/NotFound";
import SignUpTerms from "../pages/signUpTerms";
import SignupMessage from "../pages/message";
import SignIn from "../pages/signin";
import SignInOtp from "../pages/loginOtp";
import Category from "../pages/category";
import GeneratePassword from "../pages/pwdGenerate";
import Team from "../pages/team";
import Profile from "../pages/profile";
import Preference from "../pages/preference";
import CategoryContent from "../pages/categoryContent";
import Cart from "../pages/cart";
import MoreInfo from "../pages/moreInfo";
import Player from "../pages/player"; 
import Enquire from "../pages/AllEnquires/enquire";
import ViewEnquire from "../pages/AllEnquires/viewenquire";
import DynamicCategoryContent from "../pages/dynamicCategoryContent";
import MoreInfov1 from "../pages/moreinfov1";
import Partners from "../pages/partners";
import AuctionTitle from "../pages/bidding/auctiontitle";
import AuctionMoreInfo from "../pages/bidding/auctionmoreinfo";
import Bid from "../pages/bidding/bids";
import BidTerms from "../pages/bidding/bidterms";
import BidSuccess from "../pages/bidding/bidssuccess";
import ViewBid from "../pages/bidding/viewBidHistroy";
import News from "../pages/news";


const Routes = () => {
  return (
    <Switch>
   
      <Route path={'/'} exact component={Home} />

      <Route path={`/signup`} component={SignUp} />
      <Route path={`/login`} component={SignIn} />
      <Route path={`/loginotp`} component={SignInOtp} />
      <Route path={`/generatepassword`} component={GeneratePassword} />
      {/* <Route path={`/terms`} component={SignUpTerms} /> */}
      <Route path={`/terms/:id`} component={SignUpTerms} />
      <Route path={`/message`} component={SignupMessage} />

      <Route path={`/partners`} component={Partners} />
      <Route path={`/team`} component={Team} />
      <Route path={`/aboutus`} component={AboutUs} />
      <Route path={`/termsandconditions`} component={Terms} />
      <Route path={`/privacy`} component={Privacy} />
      <Route path={`/contactus`} component={ContactUs} />
      
      <Route path={`/enquire`} component={Enquire} />
      <Route path={`/viewenquire/:id`} component={ViewEnquire} />

      <Route path={`/moreinfo/:id`} component={MoreInfo} />
      <Route path={`/moreinfov1/:id`} component={MoreInfov1} />

      <Route path={`/category`} component={Category} /> 
      <Route path={`/content/`} component={CategoryContent} />
      <Route path={`/contentv1/`} component={DynamicCategoryContent} />
      <Route path={`/content/:id`} component={CategoryContent} />
      <Route path={`/news`} component={News} />
      <Route path={`/preference`} component={Preference} />
      <Route path={`/auction`} component={AuctionTitle} />
      <Route path={`/auctionmoreinfo/:id`} component={AuctionMoreInfo} />
      <Route path={`/player`} component={Player} />
      <Route path={`/bids/:id`} component={Bid} />
      <Route path={`/bidterms`} component={BidTerms} />
      <Route path={`/bidsuccess`} component={BidSuccess} />
      <Route path={`/viewbid/:id/:isgroup`} component={ViewBid} />

      <Route path={`/cart`} component={Cart} />
    
      <Route path={`/profile`} component={Profile} />
      <Route path={`*`} component={NotFound} />
    </Switch>
  );
};

export default Routes;
