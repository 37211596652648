/***
 **Module Name: category
 **File Name :  category.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains category page details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router";
import Select from 'react-select';
import { contentContext } from "../context/contentContext";
import Modal from 'react-bootstrap/Modal';
import InactivePopup from "./inactivePopup";
import SessionPopup from "./SessionPopup";
import moment from "moment";
import axios from "axios";

const AdvanceSearch = ({advSearchCall,selectedData,categoryData}) => {
  const history = useHistory();
  const [lookup, setLookUp] = useState({});
  const [categoryError, setCategoryError] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  let { lambda, appname } = window.app;

  const { searchPayload, setShowSessionPopupup, showSessionPopupup, inactiveFlag, setInactiveFlag, setSearchPayload, Categories, categoryName1, setInitialCategoriesData1, setPageNumber, setPerpage, setShowCategoryData, setSearchFunActive, setIsLoading, setMenuCategoryName, isLoading, setParamType, setCategorySearchPayload, setTitleSearchPayload, SetSearchTitleInput, selectedOptions, setSelectedOptions, setFormValuesForContent, setCategoryFields, setContentSearchFlag,
  } = useContext(contentContext)
console.log("selectedData--->",selectedData)
  const formatEmailCall = () => {
    try {

      const clientid = localStorage.getItem("clientid")
      axios({
        method: 'POST',
        url: lambda + "/formatEmail?appname=" + appname + "&clientid=" + clientid,
      })
        .then(function (response) {
          if (response.data.result == "Invalid token or Expired") {
            setShowSessionPopupup(true)
          } else if (response.data.result == "Client inactived") {
            setInactiveFlag(true)

          } else {


            console.log(response)
          }
        });

    } catch {
      console.log("format email error");
    }
  }
  function onConfirm() {
    setSuccess(false)
    formatEmailCall()
  };

  const returnArray = (arr) => {
    let arr2 = []
    arr.map((eachItem) => {
      arr2.push(eachItem.value)
    })
    return arr2
  }


  const handleMultiSelect = (selected, key) => {
    let selectedArray = returnArray(selected)
    const updatedObject = { ...searchPayload, [key]: selectedArray };

    // Iterate through the keys of the object
    for (const key in updatedObject) {
      if (updatedObject.hasOwnProperty(key)) {
        const value = updatedObject[key];

        // Check if the value is an empty string, empty array, or undefined
        if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
          // Delete the key from the object
          delete updatedObject[key];
        }
      }
    }
    console.log("multiselect---->", updatedObject)
    setSearchPayload(updatedObject)
  };




  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
    Categories();
    GetLookUp();
  }, []);

  useEffect(() => {
    if (selectedData?.name) {
      setSearchPayload((prevPayload) => ({
        ...prevPayload,
        category: [selectedData.name],
      }));
     
      console.log("selectedddddd",selectedData)
      console.log("searchPayload---->",searchPayload)
    }
  }, [selectedData]); 

  const handleClear = () => {
    setSearchPayload({})
    setErrorMsg({})
    setSelectedOptions([])
    setShowCategoryData(true)

    setMenuCategoryName("")
    setPerpage(18)
    setPageNumber(1)
    setParamType("")
    setFormValuesForContent([])

  }

  const convertValues = () => {
    let obj = { ...searchPayload }

    // if (searchPayload.hasOwnProperty('noofepisodes')) {
    //   obj = { ...obj, noofepisodes: parseInt(searchPayload.noofepisodesfrom,searchPayload.noofepisodesto) }
    // }
    if (searchPayload.hasOwnProperty('noofepisodes')) {
      obj = {
        ...obj,
        noofepisodes: {
          ...(searchPayload.noofepisodes.noofepisodesfrom?.length > 0 && {
            noofepisodesfrom: parseInt(searchPayload.noofepisodes.noofepisodesfrom)
          }),
          ...(searchPayload.noofepisodes.noofepisodesto?.length > 0 && {
            noofepisodesto: parseInt(searchPayload.noofepisodes.noofepisodesto)
          })
        }
      };



    }

    if (Array.isArray(searchPayload.typeofrights) && (searchPayload.typeofrights.includes('AVOD') ||
      searchPayload.typeofrights.includes('SVOD') ||
      searchPayload.typeofrights.includes('TVOD'))) {
      obj = { ...obj, typeofrights: [...new Set([...searchPayload.typeofrights, "Digital", "OTT"])] };

    }
    if (Array.isArray(searchPayload.typeofrights) && (searchPayload.typeofrights.includes('Digital') ||
      searchPayload.typeofrights.includes('OTT'))) {
      obj = { ...obj, typeofrights: [...new Set([...searchPayload.typeofrights, "OTT", "AVOD", "SVOD", "TVOD", "Digital"])] };

    }



    if (searchPayload.hasOwnProperty('languages')) {
      obj = { ...obj, dubbinglanguages: searchPayload.languages }
    }
    if (searchPayload.hasOwnProperty('releaseyear')) {
      obj = { ...obj, releaseyear: parseInt(searchPayload.releaseyear) }
    }
    if (searchPayload.hasOwnProperty('noofseasons')) {
      obj = { ...obj, noofseasons: parseInt(searchPayload.noofseasons) }
    }

    // if(searchPayload.hasOwnProperty('productionyear')){
    //   obj = {...obj,productionyear: parseInt(searchPayload.productionyear)}
    // }
    if (searchPayload.hasOwnProperty('IMDBrating')) {
      obj = { ...obj, IMDBrating: parseFloat(searchPayload.IMDBrating) }
    }
    if (searchPayload.hasOwnProperty('availabilityofscripts')) {
      obj = { ...obj, availabilityofscripts: searchPayload.availabilityofscripts.toLowerCase() === "true" }
    }
    if (searchPayload.hasOwnProperty('isMEavailable')) {
      obj = { ...obj, isMEavailable: searchPayload.isMEavailable.toLowerCase() === "true" }
    }

    return obj
  }
  const searchSubmit = async () => {
    const isValid = validateSearchData();
    if (isValid) {
      try {
        let payload = convertValues();

        setInitialCategoriesData1([]);
        setContentSearchFlag(true);
        setIsLoading(true); 
        setSearchFunActive(true);
        setMenuCategoryName("");
        setPerpage(18);
        setPageNumber(1);
        SetSearchTitleInput("");
        setParamType("advancedSearch");
        setCategorySearchPayload({});
        setTitleSearchPayload({});
        setSearchPayload(searchPayload);

        advSearchCall(payload);
      } catch (error) {
        console.error("Error in searchSubmit:", error);
      }
    }
  };



  // const onChangeSearch = (e) => {

  //   searchPayload[e.target.name] = e.target.value
  //   const updatedObject = { ...searchPayload };

  //   // Iterate through the keys of the object
  //   for (const key in updatedObject) {
  //     if (updatedObject.hasOwnProperty(key)) {
  //       const value = updatedObject[key];

  //       // Check if the value is an empty string, empty array, or undefined
  //       if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
  //         // Delete the key from the object
  //         delete updatedObject[key];
  //       }
  //     }
  //   }
  //   console.log('updatedObject', updatedObject)
  //   setSearchPayload(updatedObject)


  // };


  const validateSearchData = () => {
    let formIsValid = true;
    let error = { ...errorMsg };

if (parseInt(searchPayload.noofepisodes?.noofepisodesfrom) >= parseInt(searchPayload.noofepisodes?.noofepisodesto)) {
      error['noofepisodesfrom'] = `max value should be greater than the min value`;
     
      formIsValid = false;
    }


    setErrorMsg(error);
    return formIsValid;
  };



  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    
    const updatedObject = { ...searchPayload };
    const regex = /^[0-9]+(\.[0-9]{0,1})?$/;
  
    if (name === "IMDBrating") {
      const parsedValue = parseFloat(value);
      if (value === '' || (regex.test(value) && parsedValue >= 1 && parsedValue <= 10)) {
        updatedObject[name] = value;
      } else {
        delete updatedObject[name];
      }
    } else if (name === "noofepisodesfrom" || name === "noofepisodesto") {
      setErrorMsg({})
      if (value === '' || regex.test(value)) {
        updatedObject.noofepisodes = {
          ...updatedObject.noofepisodes,
          [name]: value,
        };
    
        
        if (!updatedObject.noofepisodes.noofepisodesfrom && !updatedObject.noofepisodes.noofepisodesto) {
          delete updatedObject.noofepisodes;
        }
      } else {
        delete updatedObject.noofepisodes[name];
      }
    }
    else if (name === "noofseasons") {
      if (value === '' || (regex.test(value) && parseFloat(value) >= 1)) {
        updatedObject[name] = value;
      } else {
        delete updatedObject[name];
      }
    } else {
      updatedObject[name] = value;
    }
  
    // Iterate through the keys of the object
    for (const key in updatedObject) {
      if (updatedObject.hasOwnProperty(key)) {
        const value = updatedObject[key];
  
        // Check if the value is an empty string, empty array, or undefined
        if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
          // Delete the key from the object
          delete updatedObject[key];
        }
      }
    }
  
    setSearchPayload(updatedObject);
    
  };
  





  const GetLookUp = async (e) => {
    try {
      let arrayType = ["country", "territories", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language"];

      const response = await tmdbApi.getLookUp({
        type: arrayType,
        "sortBy": "name"

      });
      console.log(response);
      if (response.statusCode == 200) {
        let arrayType = ["country", "territories", "genre", "videoformat", "resolution", "musicgenre", "rights", "cuisine", "sports", "certificate", "language"];

        let lookupsData = response.result || []

        arrayType.map((eachItem) => {
          let arr1 = []
          lookupsData.map((item1) => {
            if (item1.type == eachItem) {
              arr1.push(item1.name)
            }
          });
          lookup[eachItem] = arr1
          setLookUp({ ...lookup });
        })
        // console.log('lookup', lookup)

      }

    } catch {
      console.log("error");
    }
  }


  const k = categoryName1.map(eachh => eachh.value)
  let defaultFields = ['dubbinglanguages', 'languages', 'typeofrights', 'genre', 'videoquality', 'territoriesavailable', 'releaseyear']
  // let defaultFields = ['countryorigin', 'dubbinglanguages', 'languages', 'typeofrights','genre','videoquality', 'certificate','territoriesavailable','subtitleslanguages','releaseyear', 'IMDBrating', 'cast', 'director']
  // console.log('kksssss',k)
  // console.log('search category',searchPayload?.category)
  var fields = [];
  let selectedCategory = (searchPayload?.category === undefined || searchPayload?.category.length <= 0) ? k : searchPayload?.category
  // console.log('selectedCategory',selectedCategory)
  // console.log('props.categoryData',props.categoryData)
  const results = categoryData.filter(({ name: item }) => selectedCategory.includes(item));

  // console.log('results',results)

  const kArr = results.map(eachItem => {
    const kk = Object.keys(eachItem.fields).map(subItem => {
      // Iterate through the original array
      const existingObjectIndex = fields.findIndex(obj => obj.name === eachItem.fields[subItem].name);

      // Check if the current object has mandatory: true
      if (eachItem.fields[subItem].mandatory) {
        // If there's an existing object with the same name, replace it with the current object
        if (existingObjectIndex !== -1) {
          fields[existingObjectIndex] = eachItem.fields[subItem];
        } else {
          fields.push(eachItem.fields[subItem]);
        }
      } else if (existingObjectIndex === -1) {
        // If the current object has mandatory: false and no existing object with the same name, add it
        fields.push(eachItem.fields[subItem]);
      }


    })

  })

  // fields = fields.filter(each => each.searchable)
  if ((searchPayload?.category === undefined || searchPayload?.category.length <= 0)) {
    const filterFields = fields.filter(({ name: item }) => defaultFields.includes(item));
    fields = filterFields;
    // console.log('filterFields',filterFields)
  } else {
    fields = fields.filter(each => each.searchable)
  }



  // console.log('fields-->', fields)
  // console.log('selectedOptions', selectedOptions)
  // console.log('categoryName1', categoryName1)
  const handleChange = (selected, isFormatSigned) => {
    setErrorMsg({})


    console.log('handle change ', selected.value)
    var isFormatSigned = true;
    let selectedArray = []
    let k = selected.value

    categoryData.map((item) => {
      if (item.name === "FORMATS") {
        let signed = localStorage.getItem("signed");
        if (signed === "false" || signed == undefined || signed == 'undefined' || signed === "") {
        } else {
          if (item.name === k) {
            setCategoryFields(item.fields);
          }
        }
      }
      else {
        if (item.name === k) {
          setCategoryFields(item.fields);
        }
      }

    });

    if (k === "FORMATS") {
      let signed = localStorage.getItem("signed");
      if (signed === "false" || signed == undefined || signed == 'undefined' || signed === "") {
        setSuccess(true);
        isFormatSigned = false
      } else {
        selectedArray.push(k)
        isFormatSigned = true
      }
    } else {
      selectedArray.push(k)
    }

    if (selectedArray.length > 0) {
      console.log('searchpayload', searchPayload['category'])
      console.log('selectedArray', selectedArray)
      searchPayload['category'] = selectedArray
      searchPayload['noofepisodes'] = {}
      delete searchPayload.noofepisodes;

    }

    setCategoryError("")
    // setSearchPayload({ ...searchPayload })
    if (isFormatSigned === true) {
      setSelectedOptions(selected);
    }


    const ks = categoryName1.map(eachh => eachh.value)

    var fields = [];
    let selectedCategory = (searchPayload?.category === undefined || searchPayload?.category.length <= 0) ? ks : searchPayload?.category
    const results = categoryData.filter(({ name: item }) => selectedCategory.includes(item));


    const kArr = results.map(eachItem => {
      const kk = Object.keys(eachItem.fields).map(subItem => {
        // Iterate through the original array
        const existingObjectIndex = fields.findIndex(obj => obj.name === eachItem.fields[subItem].name);

        // Check if the current object has mandatory: true
        if (eachItem.fields[subItem].mandatory) {
          // If there's an existing object with the same name, replace it with the current object
          if (existingObjectIndex !== -1) {
            fields[existingObjectIndex] = eachItem.fields[subItem];
          } else {
            fields.push(eachItem.fields[subItem]);
          }
        } else if (existingObjectIndex === -1) {
          // If the current object has mandatory: false and no existing object with the same name, add it
          fields.push(eachItem.fields[subItem]);
        }


      })

    })
    fields = fields.filter(each => each.searchable)
    let tempSearchPayload = { ...searchPayload }
    console.log('tempsearch payload==>', tempSearchPayload)
    console.log('fields  array==>', fields)
    const filteredPayload = Object.keys(searchPayload)
      .filter(key => fields.some(item => item.name === key))
      .reduce((obj, key) => {
        obj[key] = searchPayload[key];
        return obj;
      }, {});


    console.log('filteredPayload in useeffect---->', filteredPayload);
    const updatedObject = { ...filteredPayload };

    // Iterate through the keys of the object
    for (const key in updatedObject) {
      if (updatedObject.hasOwnProperty(key)) {
        const value = updatedObject[key];

        // Check if the value is an empty string, empty array, or undefined
        if (value === '' || (Array.isArray(value) && value.length === 0) || value === undefined) {
          // Delete the key from the object
          delete updatedObject[key];
        }
      }
    }
    //  console.log('updatedObject',updatedObject)
    setSearchPayload(updatedObject)

  };
  // console.log('fields',fields)
  return (
    <>
      {inactiveFlag && <InactivePopup />}
      {!inactiveFlag && showSessionPopupup && <SessionPopup />}

      <Modal className="access-denied" show={success} >

        <div className="modal-body enquiry-form">
          <div className="container">
            <button className="close-btn" onClick={e => onConfirm()}><span className="material-icons">close</span></button>
            <span className="material-icons access-denied-icon">https</span>
            <h3>Access Denied</h3>
            <p>You are not eligible to view this content.</p>
            <p>Please contact administrator</p>
            <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>close</button>
          </div>
        </div>

      </Modal>


      <div className="left-block adv_search">
        <div className="adv_header">
          <h6>
            <span className="material-symbols-outlined">search</span>
            Advanced Search

          </h6>
        </div>
        <div className="adv_body">
          <div className="adv_body-inner">
            <div className="form-group">
              <label htmlFor="example-text-input" className="col-form-label">category</label>
              <Select isMulti={false}
                placeholder='Select Category'
                onChange={handleChange}
                options={categoryName1}
                isDisabled={isLoading}
                // value={selectedOptions}
                // options={lookup && lookup['category'] && lookup['category'].map((eachItem) => { return { value: eachItem, label: eachItem } })}
                value={searchPayload && searchPayload['category'] && Array.isArray(searchPayload['category']) ? searchPayload['category']?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
              />
              <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{categoryError}</span>

            </div>
            <div className="form-group">
              <label htmlFor="example-text-input" className="col-form-label">Title</label>
              <input
                type="text"
                className="form-control for-clear"
                id="txtAdvSearchTitle"
                name="title"
                placeholder="Title"
                disabled={isLoading}
                onChange={(e) => onChangeSearch(e)}
                value={searchPayload?.title || ""}
              />

            </div>

            {fields && fields.map((eachfield) => {
              if (eachfield.inputtype === "multiselectdropdown"
                && eachfield.name !== 'category'
                && eachfield.name !== 'dubbinglanguages') {
                return (
                  <div className="form-group" key={eachfield.name}>
                    <label htmlFor="example-text-input" className="col-form-label">
                      {eachfield.name === "languages" ? "Languages (Original & Dubbed)" : eachfield.display}
                    </label>

                    <Select
                      isMulti={true}
                      placeholder={"Select " + eachfield.display}
                      id={eachfield.name}
                      className="mul-cls"
                      isDisabled={isLoading}
                      onChange={(e) => handleMultiSelect(e, eachfield.name)}
                      options={lookup && lookup[eachfield.lookupvalue] && lookup[eachfield.lookupvalue].map((eachItem) => { return { value: eachItem, label: eachItem } })}
                      value={searchPayload && searchPayload[eachfield.name] && Array.isArray(searchPayload[eachfield.name]) ? searchPayload[eachfield.name]?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
                    />
                  </div>
                );
              }
            })}

            {fields && fields.map((eachfield) => {
              if ((eachfield.inputtype === 'text' || eachfield.inputtype === "number") && eachfield.name != 'title'
                //  && eachfield.searchable === true
              ) {

                return (

                  <div className="form-group">
                    <label htmlFor="example-text-input" className="col-form-label">{eachfield.display}</label>

                    {eachfield.name === "releaseyear" ?
                      <div className="onwards">
                        <input
                          type={eachfield.inputtype} name={eachfield.name}
                          className="form-control for-clear"
                          id="example-email-input"
                          disabled={isLoading}
                          placeholder={'Enter ' + eachfield.display}
                          value={searchPayload[eachfield.name] || ""}
                          onChange={(e) => onChangeSearch(e)}
                        />
                        <span>onwards</span>

                      </div> : eachfield.name === "noofepisodes" ? (
                        <div className="d-flex episodes">
                          <input
                            type="number"
                            name="noofepisodesfrom"
                            className="form-control for-clear"
                            id="input-noofepisodesfrom"
                            disabled={isLoading}
                            placeholder="Min episodes"
                            value={searchPayload?.noofepisodes?.noofepisodesfrom?.length > 0 && searchPayload?.noofepisodes?.noofepisodesfrom || ""}
                            onChange={(e) => onChangeSearch(e)}
                          />

                          <input
                            type="number"
                            name="noofepisodesto"
                            className="form-control for-clear"
                            id="input-noofepisodesto"
                            disabled={isLoading}
                            placeholder="Max episodes"
                            value={searchPayload?.noofepisodes?.noofepisodesto?.length > 0 && searchPayload?.noofepisodes?.noofepisodesto || ""}
                            onChange={(e) => onChangeSearch(e)}
                          />
                          {errorMsg?.noofepisodesfrom &&
                            <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errorMsg?.noofepisodesfrom}</span>
                          }
                        </div>
                      ) : <input
                        type={eachfield.inputtype} name={eachfield.name}
                        className="form-control for-clear"
                        id="example-email-input"
                        disabled={isLoading}
                        placeholder={'Enter ' + eachfield.display}
                        value={searchPayload[eachfield.name] || ""}
                        onChange={(e) => onChangeSearch(e)}
                      />

                    }

                  </div>)
              }
            })
            }
            {fields && fields.map((eachfield) => {
              if (eachfield.inputtype === "date" && eachfield.searchable === true) {
                return (
                  <div className="form-group">
                    <label htmlFor="example-text-input" className="col-form-label">{eachfield.display}</label>
                    <input
                      placeholder={'Enter ' + eachfield.display}
                      type={eachfield.inputtype} name={eachfield.name}
                      value={searchPayload && searchPayload[eachfield.name] && moment(new Date(searchPayload[eachfield.name])).format('YYYY-MM-DD') || ""}
                      onChange={(e) => onChangeSearch(e)}
                      className="form-control for-clear date_input"
                      id="example-email-input"
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </div>)
              }
            })
            }
            {fields && fields.map((eachfield) => {
              if (eachfield.inputtype === "dropdown" && eachfield.searchable === true) {
                return (<div className="form-group">
                  <label htmlFor="example-text-input" className="col-form-label">{eachfield.display}</label>
                  <select className="form-select" placeholder={"Select " + eachfield.display} name={eachfield.name} value={searchPayload[eachfield.name]}
                    onChange={(e) => onChangeSearch(e)} >
                    <option value="">Select</option>
                    <option value="false">False</option>
                    <option value="true">True</option>

                  </select>
                </div>)
              }
            })
            }
            {fields && fields.map((eachfield) => {
              if (eachfield.inputtype === "textarea" && eachfield.searchable === true) {
                return (<div className="form-group">
                  <label htmlFor="example-text-input" className="col-form-label">{eachfield.display}</label>
                  <textarea id="email" placeholder={"Enter " + eachfield.display} name={eachfield.name} type="text" className="form-control" value={searchPayload[eachfield.name]} onChange={(e) => onChangeSearch(e)} />
                </div>)
              }
            })
            }



          </div >
        </div>

        <div className="adv_footer">


          <button type="button" className="clear-btn line-btn" onClick={handleClear}>
            <span className="material-icons me-1">autorenew</span>reset</button>

          <button className={`fill_btn yellow-gradient float-end ${Object.keys(searchPayload).length == 0 ? "disable" : ""}`} disabled={Object.keys(searchPayload).length == 0} onClick={searchSubmit}>
            SEARCH
          </button>
          <div className="clearfix"></div>
        </div>
      </div>

    </>
  );
};

export default AdvanceSearch;
