/***
**Module Name: category
 **File Name :  category.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains category page details.
 ***/
 import React, { useState, useEffect, useRef, useContext} from "react";
 import tmdbApi from "../api/tmdbApi";

 import { useHistory } from "react-router";
 import * as Config from "./../constants/Config";
 import Header from "../components/header/Header";
 import Footer from "../components/footer/Footer";
 import { useParams } from 'react-router-dom';
 import Modal from 'react-bootstrap/Modal';
 import axios from "axios";
 import Select from 'react-select';
 import moment from "moment";

 import SweetAlert from 'react-bootstrap-sweetalert';
 import { contentContext } from "../context/contentContext";
 import SessionPopup from "./SessionPopup"
 import InactivePopup from "./inactivePopup";

const EnquiryForm =(props)=> {
    const [enquire, setEnquire] = useState(true);
    
    const [Regions, setRegions] = useState([]); 
    const [rightsAvailableError, setRightsAvailableError] = useState("");
    const [videoformatData, setVideoformatData] = useState({});

  
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [lookup, setLookUp] = useState({});

    const [languagesData, setlanguagesData] = useState({});
    const [countryData, setCountryData] = useState({});
    const [multiSelectFields, setMultiSelectFields] = useState({});
    const [enquiryFormValues, setEnquiryFormValues] = useState({});

    

    // const [enqFormValues, setContentformValues] = useState(props.enqFormValues !=[] ? props.enqFormValues : []);
    
    const {clientData,wishListData,inactiveFlag,setInactiveFlag, setWishListData,enqFormValues, SetEnqFormValues} = useContext(contentContext)

    var img = props.img
    const handleCloseEnq = () => {
      setEnquire(false)
    };
    const submitForm = (e)=> {
        // console.log('submit clicked')
        const isValid = formvalidation();
        // if(isValid){
            submitEnquiryFun();
        // }
      
    }
    const handleRightsavailableError =() => {
        setRightsAvailableError("")
    }

function formvalidation() {
    let formIsValid = true;
    
    // if (!enquiryFormValues['rightsAvailable']) {
    //     setRightsAvailableError("Please enter rights available");
    //   formIsValid = false;
    // }
    
    return formIsValid;
  }
    const submitEnquiryFun = async () => {
        try {
            const clientid = localStorage.getItem("clientid")
            console.log('multiSelectFields.dubbinglanguages',multiSelectFields.dubbinglanguages)
            console.log('multiSelectFields.dubbinglanguages',multiSelectFields.dubbinglanguages)
            const response = await tmdbApi.submitEnquiry(
            
            {
                "clientid": clientid,
                "companyid":clientData.companyid,                
                "rightsAvailable": enquiryFormValues.rightsAvailable,
                "comments": enquiryFormValues.additionalCom,
                "request": enquiryFormValues.request,
                "requiredtodate": enquiryFormValues.rightsAvailableToDate,
                "requiredfromdate": enquiryFormValues.rightsAvailableFromDate,
                "videoformatsrequired": multiSelectFields && multiSelectFields.videoformat,
                "dubbinglanguagesrequired": multiSelectFields.dubbinglanguages,
                "exclusive": enquiryFormValues.exclusivity,
                "queries": enquiryFormValues.queries,
                "regionsrequired": multiSelectFields.regions,
                "content": enqFormValues,
                
            }
          );
         
          if (response.statusCode === 200) {
            if(response.result == "Invalid token or Expired"){
                setShowSessionPopupup(true)
              }else if (response.result == "Client inactived") {
                setInactiveFlag(true)
        
              }else{
            
            const set1 = new Set(enqFormValues.map((eachone)=>eachone.contentid))
            console.log('filterd set1',set1)

            const filterdarray = wishListData.filter((el)=> !set1.has(el.contentid))
            console.log('filterd arrrraa',filterdarray)

            
            SetEnqFormValues([])
            setWishListData(...wishListData,filterdarray)
            console.log(response);
            props.AddNotify()
            props.handleCloseEnq()
            }
          }
        } catch {
          console.log("error");
        }
      };
      useEffect(() => {
         GetLookUp();
     
      }, []);
      // console.log('multiselect fielsd',multiSelectFields)
      const GetLookUp = async (e) => {
        try {
          let arrayType = ["country", "videoformat", "language","territories"];
    
          const response = await tmdbApi.getLookUp({
            type: arrayType,
            "sortBy":"name"
    
          });
          console.log(response);
          if (response.statusCode == 200) {
            let arrayType = ["country", "videoformat", "language","territories"];
            let lookupsData = response.result || []
            arrayType.map((eachItem) => {
              let arr1 = []
              lookupsData.map((item1) => {
                if (item1.type == eachItem) {
                  arr1.push(item1.name)
                }
              });
              lookup[eachItem] = arr1
              setLookUp({ ...lookup });
            })
             console.log('loookup',lookup)
            const countryObj = prepareObject(lookup.country)
            const territoriesObj = prepareObject(lookup.territories)
            console.log("countryObj",countryObj)
            console.log("territoriesObjt",territoriesObj)
            setCountryData([...countryObj,...territoriesObj])
            const languagesObj = prepareObject(lookup.language)
            setlanguagesData(languagesObj)
            const videoformatObj = prepareObject(lookup.videoformat)
            setVideoformatData(videoformatObj)
    
          }
    
        } catch {
          console.log("error");
        }
      }
      const prepareObject = (item) => {
        const resultArr = item.map((eachItem) => { return { value: eachItem, label: eachItem }; })
        return resultArr
      }

      const handleMultiSelect = (selected,key) => {
        let selectedArray = returnArray(selected)
        setMultiSelectFields({...multiSelectFields,[key]:selectedArray})
      };
      const returnArray = (arr) => {
        let newArr = []
        arr.map((eachItem) => {
            newArr.push(eachItem.value)
        })
     
        return newArr
      }
      const handleEnquireForm = (e) => {
      let name = e.target.name
      let value = e.target.value
      if (e.target.name == "rightsavailabletodate" || e.target.name == "rightsavailablefromdate") {
        let date = moment(new Date(value)).toISOString();
        setEnquiryFormValues({ ...enquiryFormValues, [name]: date });
      }
      setEnquiryFormValues({...enquiryFormValues,[name]:value})
      }
    
    //   console.log('enquiryFormValues',enquiryFormValues)
      return(
        <>
          {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

            {/* {showSessionPopupup && <SessionPopup />} */}
                        <div className="modal-header">
                    <img src={img + Config.imgmiddle + "logo.png"} alt="" className="modal-logo" />
                    <h4 className="modal-title">Enquiry Form</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={props.handleCloseEnq}></button>
                </div>
                <div className="modal-body enquiry-form">
                    <div className="container">
                        <div className="row">
                        <h6>{enqFormValues && enqFormValues.length>0 ? enqFormValues.length : 0} Titles Selected</h6>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20"  value={additionalCom} onChange={(e) => setAdditionalCom(e.target.value)}  className="form-control">Tell us more about your requirement</textarea>
                                    <label htmlFor="floatingInput">Additional Comments</label> */}
                                     <label htmlFor="example-text-input" className="col-form-label">Additional Comments</label>
                                     <textarea id="email" name="additionalCom"  type="text" className="form-control" spellcheck="false"  value={enquiryFormValues.additionalCom} 
                                     
                                     onChange={(e) => handleEnquireForm(e)} ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20" className="form-control" value={rightsAvailable} onChange={(e) => setRightsAvailable(e.target.value)} onFocus={(e)=> handleRightsavailableError(e)}>Select Rights</textarea>
                                    <label htmlFor="floatingInput">Rights Available<span>*</span></label>
                                    <span className="errormsg" style={{  fontWeight: 'bold',color: 'red' }}>{rightsAvailableError}</span> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Required Rights</label>
                                     <textarea id="email" name="rightsAvailable"  type="text" className="form-control" spellcheck="false"  value={enquiryFormValues.rightsAvailable} onChange={(e) => handleEnquireForm(e)} onFocus={(e)=> handleRightsavailableError(e)}></textarea>
                                     <span className="errormsg" style={{  fontWeight: 'bold',color: 'red' }}>{rightsAvailableError}</span> 
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                {/* <input id="email" name="releasedate" placeholder="Release from Date" type="date" value={rightsAvailableFromDate} onChange={(e) => setRightsAvailableFromDate(e.target.value)}className="form-control" />
                                    <label htmlFor="floatingInput">Rights Available from Date
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Required rights available from </label>
                                    <input id="email" name="rightsAvailableFromDate" placeholder="Release Date" type="date" className="form-control"  value={enquiryFormValues.rightsAvailableFromDate} onChange={(e) => handleEnquireForm(e)}/>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                {/* <input id="email" name="releasedate" placeholder="Release Date" type="date" value={rightsAvailableToDate} onChange={(e) => setRightsAvailableToDate(e.target.value)}className="form-control" />
                                    <label htmlFor="floatingInput">Rights Available to Date
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Required rights available to </label>
                                    <input id="email" name="rightsAvailableToDate" placeholder="Release Date" type="date" className="form-control" value={enquiryFormValues.rightsAvailableToDate} onChange={(e) => handleEnquireForm(e)}/>
                                </div>
                            </div>
                           
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="email" className="form-control"  value={videoFormat} onChange={(e) => setVideoFormat(e.target.value)} id="floatingInput" placeholder="Eg: MP4, MOV, AVI etc" />
                                    <label htmlFor="floatingInput">Video Format
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Exclusivity</label>
                                    <input type="text" className="form-control for-clear" id="example-email-input" name="exclusivity" placeholder="Exclusivity" value={enquiryFormValues.exclusivity} onChange={(e) => handleEnquireForm(e)} />
                                    
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="email" className="form-control"  value={Regions} onChange={(e) => setRegions(e.target.value)} id="floatingInput" placeholder="Eg: MP4, MOV, AVI etc" />
                                    <label htmlFor="floatingInput">Regions
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Regions</label>
                                   
                                      <Select isMulti={true}
                                        placeholder='Regions'
                                        onChange={(e)=> handleMultiSelect (e,'regions')}
                                        options={countryData}
                                        value={multiSelectFields && multiSelectFields.regions && multiSelectFields.regions.length > 0 ? multiSelectFields.regions?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}

                                    />
                                </div>
                            </div>

                           
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="Your Name" className="form-control" id="floatingInput" placeholder="Enter Languages"  value={dubbingLanguages} onChange={(e) => setDubbingLanguages(e.target.value)} />
                                    <label htmlFor="floatingInput">Dubbing Languages
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Dubbing Languages</label>
                                     <Select isMulti={true}
                                            placeholder='Dubbing Languages'
                                            onChange={(e)=> handleMultiSelect (e,'dubbinglanguages')}
                                            options={languagesData}
                                            value={multiSelectFields && multiSelectFields.dubbinglanguages && multiSelectFields.dubbinglanguages.length > 0 ? multiSelectFields.dubbinglanguages?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}

                                        />
                                         </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <input type="email" className="form-control"   value={exclusivity} onChange={(e) => setExclusivity(e.target.value)}  id="floatingInput" placeholder="name@example.com" />
                                    <label htmlFor="floatingInput">Exclusivity
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Video Quality</label>
                                    <Select isMulti={true}
                                        placeholder='Video quality'
                                        onChange={(e)=> handleMultiSelect (e,'videoformat')}
                                        options={videoformatData}
                                        value={multiSelectFields && multiSelectFields.videoformat &&  multiSelectFields.videoformat.length>0 ? multiSelectFields.videoformat?.map((eachItem) => { return { value: eachItem, label: eachItem } }):[]}

                                        isClearable={true}
                                    />
                               </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20"  value={request} onChange={(e) => setRequest(e.target.value)}  className="form-control ">Enter Request</textarea>
                                    <label htmlFor="floatingInput">Special Request
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Special Request</label>
                                     <textarea id="email" name="request"  type="text" className="form-control" spellcheck="false"   value={enquiryFormValues.request} onChange={(e) => handleEnquireForm(e)} ></textarea>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group mb-2">
                                    {/* <textarea name="message" rows="20" className="form-control " value={queries} onChange={(e) => setQueries(e.target.value)}   >Query</textarea>
                                    <label htmlFor="floatingInput">Enter Query
                                    <span>*</span>
                                    </label> */}
                                    <label htmlFor="example-text-input" className="col-form-label">Enter Query</label>
                                     <textarea id="email" name="queries"  type="text" className="form-control" spellcheck="false" value={enquiryFormValues.queries}  onChange={(e) => handleEnquireForm(e)}   ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="fill_btn yellow-gradient float-end" onClick={(e)=>submitForm(e)}>Submit</button>
                    <div className="clearfix"></div>
                </div>
        </>
    )
}
export default EnquiryForm