/***
**Module Name: About Us
 **File Name :  AboutUS.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains About us details.
 ***/
import React, { useState, useEffect, useContext } from "react";

// import "../../src/assets/css/style.css"
import tmdbApi from "../../api/tmdbApi";
import InsideHeader from "../../components/InnerHeader/Header";
import Footer from "../../components/footer/Footer";
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from 'jquery';
import axios from "axios";

import * as Config from "../../constants/Config";
import { useHistory } from "react-router";
import { useParams, Link } from 'react-router-dom';

import { contentContext } from "../../context/contentContext";
import SessionPopup from "../SessionPopup"
import Select from 'react-select';
import moment from "moment";
import InactivePopup from "../inactivePopup";

let { lambda, appname } = window.app;


const ViewEnquire = () => {
    const history = useHistory();
    let { id } = useParams();

    const [isLoadCart, setIsLoadCart] = useState(false);
    const [config, setConfig] = useState({});
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);

    const [enquireData, setEnquireData] = useState([]);

    const [ShowAdditionalComm, setShowAdditionalComm] = useState(false);
    const [activeCommentsData, setActiveCommentsData] = useState("");


    const { menus,setShowCategoryData,inactiveFlag,setInactiveFlag, isShowMyContent,clientData,setContentSearchFlag,contentSearchFlag, setRoute  } = useContext(contentContext)
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }

        getEnquireFun();
        clientActivity()
    }, []);
    useEffect(() => {
        if (id == undefined) {
            history.push("/signup");
        }
        setContentSearchFlag(false)
        setRoute("notcontent")
        }, []);
    useEffect(() => {
        if (window.site) {
            setConfig(window.site);
            if ((!isShowMyContent && clientData && clientData.type != "SELLER") ) {
                console.log('enquire clientdata',clientData && clientData.type)
               }else{
                 history.push("/content");
               }
        }

    }, [window.site]);

    if (config.common && config.common.imagesCDN) {
        var img = config.common.imagesCDN;
    }
 
    const handleExplore = () => {
        setShowCategoryData(true)
         history.push('/content')
     }
 
   
    const getEnquireFun = async () => {
        try {
            setIsLoadCart(true)

            let payload = { enquiryid: id }
            const response = await tmdbApi.getEnquireItem(payload);
            if (response.statusCode === 200) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }else if (response.result == "Client inactived") {
                    setInactiveFlag(true)
            
                  } else {
                    let data = response.result && response.result && response.result.result ? response.result.result : []

                    if (data.length > 0) {
                        setEnquireData(data)

                    }
                    else {

                        setEnquireData([])
                        setIsLoadCart(false)
                    }
                }
               

            }
        } catch {
            setIsLoadCart(false)
          
            console.log("error");
        }

    }

    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        // const pageName = path[path.length - 2];
        var presentTime = moment();
        let payload;
        
            payload = {
                "clientid": localStorage.getItem("clientid"),
                "pagename": 'ENQUIRY INFO',
                "pageurl": window.location.href,
                "starttime": presentTime,
                "useragent": JSON.parse(localStorage.getItem("loc"))

            };

        
        let urlLink;
        if(localStorage.getItem("previousid")){
            urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        }else {
            urlLink= lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if(response.data.statusCode === 200){
                    localStorage.setItem("previousid",response.data.result)
                }
            });
    }
    // console.log('enq list datattttaa', enquireData)

    const handleBack = () => {
        history.push('/enquire')
    }



    const handleShow = (e,value) => {
        setShowAdditionalComm(true)
        setActiveCommentsData(value)

    }
    const handleClose = () => {
        setActiveCommentsData("")
        setShowAdditionalComm(false);
      };
    return (
        <>
          {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

            {/* {showSessionPopupup && <SessionPopup />} */}

            <InsideHeader activeClass={"2"} />
            <Modal show={ShowAdditionalComm} onHide={handleClose} className="modal title-des-popup order_comments">
                    <Modal.Header closeButton>
                        <Modal.Title>ADDITIONAL COMMENTS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p style={{ whiteSpace: "pre-line" }}>{activeCommentsData}</p>

                    </Modal.Body>


                </Modal>
            <div className="inner-body profile view-orders">
                <div className="body-header">
                    <div className="md-container">
                        <div className="page-header">
                            <div className="block-title">
                                <h2>View Order</h2>
                            </div>
                            <button className="plain-btn"
                                onClick={handleBack}
                            ><span className="material-icons-outlined">chevron_left
                                </span>Back</button>

                        </div>
                    </div>
                </div>
                {enquireData && enquireData.length > 0 ? (<>
 
                <div className="md-container ">
                    {enquireData && enquireData.length > 0 && enquireData.map((eachItem, i) => {
                        console.log('eachItem--->', eachItem.content.length)

                        return (
                            <div className="card-block mt-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p>
                                        {`${eachItem.content.length == 1 ? '1 Title' : eachItem.content.length > 1 ? eachItem.content.length + ' Titles' : null}`} enquired</p>
                                    <p>status:<span>{eachItem.status === undefined || eachItem.status === "" ? "NEW" : eachItem.status}</span></p>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>title</th>
                                            <th>Additional Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eachItem.content.map((item) => {
                                            let defaultImg = `${img}orasi/common/images/img-default.jpg`;
                                            
                                            let imgUrl = (item.portraitimage !="" && item.portraitimage != undefined)?  img + item.portraitimage :
                                            ( item.landscapeimage !="" && item.landscapeimage != undefined )? img + item.landscapeimage  : defaultImg
                                           

                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="d-flex ">
                                                            <img src={imgUrl + "?auto=compress,format&amp;width=40"} />
                                                            {/* <img src="https://d9nwtjplhevo0.cloudfront.net/orasidev/content/642a86eba51c1d59d95c0986/642a86eba51c1d59d95c0989_CONTENT_IMAGE_1681288429721.png?auto=compress,format&amp;width=40" /> */}
                                                            {item.title}</div></td>
                                                    <td><div className="cast">
                                                        {/* {item.comments} */}
                                                    
                                                    {item?.comments ? item?.comments.substring(0, 50) + " ":""}
                                                                               {item?.comments?.length > 50 ?
                                                                               
                                                                               (<button className="more-popup-btn"
                                                                                 onClick={e => handleShow(e,item?.comments)}>More</button>
                                                                               ) : null}
                                                    
                                                    </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                                {((eachItem.rightsAvailable && eachItem.rightsAvailable.toString().trim() != "") ||
                                    (eachItem.requiredfromdate && eachItem.requiredfromdate.toString().trim() != "") ||
                                    (eachItem.requiredtodate && eachItem.requiredtodate.toString().trim() != "") ||
                                    (eachItem.exclusive && eachItem.exclusive.toString().trim() != "") ||
                                    (eachItem.queries && eachItem.queries.toString().trim() != "") ||
                                    (eachItem.request && eachItem.request.toString().trim() != "") ||
                                    (eachItem.videoformatsrequired && typeof (eachItem.videoformatsrequired) != 'string') ||
                                    (eachItem.dubbinglanguagesrequired && typeof (eachItem.dubbinglanguagesrequired) != 'string') ||
                                    (eachItem.regionsrequired && typeof (eachItem.regionsrequired) != 'string')) &&
                                    <div className="enquiry-details">
                                        <h6>enquiry details</h6>
                                        <div className="row">
                                            {eachItem.rightsAvailable && eachItem.rightsAvailable.toString().trim() != "" ?
                                                <div className="col-md-4">
                                                    <label>rights available</label>
                                                    <p>{eachItem.rightsAvailable?.join(', ')}</p>
                                                </div>
                                                : null}
                                            {eachItem.requiredfromdate && eachItem.requiredfromdate.toString().trim() != "" ?
                                                <div className="col-md-4">
                                                    <label>rights available from date</label>
                                                    {/* <p>{moment(eachItem.requiredfromdate).format('DD-MMM-YY')}</p> */}
                                                    <p>{new Date(eachItem.requiredfromdate).toLocaleDateString('en-IN', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',

                                                    })}</p>
                                                </div>
                                                : null}
                                            {eachItem.requiredtodate && eachItem.requiredtodate.toString().trim() != "" ?

                                                <div className="col-md-4">
                                                    <label>rights available to date</label>
                                                    <p>{new Date(eachItem.requiredtodate).toLocaleDateString('en-IN', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',

                                                    })}</p>
                                                </div>
                                                : null}
                                            {eachItem.videoformatsrequired && typeof (eachItem.videoformatsrequired) != 'string' ?

                                                <div className="col-md-4">
                                                    <label>video Quality</label>
                                                    <p>{eachItem.videoformatsrequired?.join(', ')}</p>
                                                </div>
                                                : null}
                                            {eachItem.dubbinglanguagesrequired && typeof (eachItem.dubbinglanguagesrequired) != 'string' ?

                                                <div className="col-md-4">
                                                    <label>dubbing languages</label>
                                                    <p>{eachItem.dubbinglanguagesrequired?.join(', ')}</p>
                                                </div>
                                                : null}
                                            {eachItem.exclusive && eachItem.exclusive.toString().trim() != "" ?

                                                <div className="col-md-4">
                                                    <label>exclusivity</label>
                                                    <p>{eachItem.exclusive}</p>
                                                </div>
                                                : null}

                                            {eachItem.regionsrequired && typeof (eachItem.regionsrequired) != 'string' ?
                                                <div className="col-md-4">
                                                    <label>regions required</label>
                                                    <p>{eachItem.regionsrequired?.join(', ')}</p>
                                                </div>
                                                : null}
                                            {eachItem.request && eachItem.request.toString().trim() != "" ?

                                                <div className="col-md-4">
                                                    <label>request</label>
                                                    <p>{eachItem.request}</p>
                                                </div>
                                                : null}
                                            {eachItem.queries && eachItem.queries.toString().trim() != "" ?

                                                <div className="col-md-4">
                                                    <label>Queries</label>
                                                    <p>{eachItem.queries}</p>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                }
                            </div>

                        )
                    })}


                </div>

                </>) : isLoadCart ? (<>
                     <div className="orasi-preloader">
                         <img src={img+"orasi/common/images/preloader.png"} />
                     </div>
                 </>) : (<>
                     <div className="comingsoon-wrapper">
                         <div className="comingsoon-block">
                             <img src={img+"orasi/common/images/shopping-list.png"} />
                             <h6>Your orders are empty!</h6>
                             <p>Looks like you have not added anything to your cart.<br />Go ahead and explore top categories.</p>
                             <button className="fill_btn yellow-gradient mt-2" onClick={handleExplore}>
                                 Explore Now
                             </button>
                         </div>
                     </div>
                 </>)}
 

            </div>
            <Footer menus={menus} />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />

        </>
    );
};

export default ViewEnquire;