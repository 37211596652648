/***
**Module Name: About Us
 **File Name :  AboutUS.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains About us details.
 ***/
 import React, { useState, useEffect, useContext } from "react";

 // import "../../src/assets/css/style.css"
 import tmdbApi from "../../api/tmdbApi";
 import InsideHeader from "../../components/InnerHeader/Header";
 import Footer from "../../components/footer/Footer";
 import Modal from 'react-bootstrap/Modal';
 
 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 
 import $ from 'jquery';
 import axios from "axios";
 
 import * as Config from "../../constants/Config";
 import { useHistory } from "react-router";
 import { contentContext } from "../../context/contentContext";
 import SessionPopup from "../SessionPopup"
 import moment from "moment";
 import InactivePopup from "../inactivePopup";
 let { lambda, appname } = window.app;
 
 
 const Enquire = () => {
     const history = useHistory();
     // const [menus, setMenus] = useState([]);
     const [isLoadCart, setIsLoadCart] = useState(false);
     const [config, setConfig] = useState({});
    
     
     const [pageNumber, setPageNumber] = useState(1);
     const [perPageConst, setPerPageConst] = useState(10);
     const [perpage, setPerpage] = useState(10);
     const [assetTotal, setAssetTotal] = useState(0);
     const [activeCheckId, setActiveCheckId] = useState("");
 
    
 
     const [totalPages, setTotalPages] = useState(0);
     const [totalPagesArray, setTotalPagesArray] = useState(0);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
 
 

     const [enquireListData, setEnquireListData] = useState([]);
     
     
     const [ShowAdditionalComm, setShowAdditionalComm] = useState(false);
    const [activeCommentsData, setActiveCommentsData] = useState("");
    let img = window.app.img;
     const { clientData,inactiveFlag,setInactiveFlag, isShowMyContent,setShowCategoryData,menus ,setContentSearchFlag,contentSearchFlag, setRoute} = useContext(contentContext)
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         // appMenus();
         getEnquireListFun();
         clientActivity();
       
 
     }, [perpage, pageNumber]);

useEffect(()=>{
    setContentSearchFlag(false)
    setRoute("notcontent")
},[]);
 
     useEffect(() => {
         if (window.site) {
             setConfig(window.site);
             if ((!isShowMyContent && clientData && clientData.type != "SELLER") ) {
              console.log('enquire clientdata',clientData && clientData.type)
             }else{
               history.push("/content");
             }
         }
 
     }, [window.site]);
     console.log('enquire clientdata11111111111',clientData)
     if (config.common && config.common.imagesCDN) {
         img = config.common.imagesCDN;
    }
  console.log('isShowMyContent',isShowMyContent)

     const getEnquireListFun = async () => {
         try {
             setIsLoadCart(true)
             
 
             let payload = { perpage, pageNumber }
             const response = await tmdbApi.getEnquireList(payload);
             if (response.statusCode === 200) {
                 if(response.result == "Invalid token or Expired"){
                     setShowSessionPopupup(true)
                    }else if (response.result == "Client inactived") {
                        setInactiveFlag(true)
                
                      }else{
                 let data = response.result && response.result && response.result.result ? response.result.result : []
                 if (data.length > 0) {
                  setEnquireListData(data)
 
                     let assetTotal = response.result.count || 0
                     setAssetTotal(assetTotal)
                     if (assetTotal > 0) {
                         let totalPages = assetTotal / perpage;
                         totalPages = Math.ceil(totalPages)
                         let totalArray = [];
                         for (var i = 0; i < totalPages; i++) {
                             totalArray.push(i + 1)
                         }
                         setTotalPagesArray(totalArray)
                         setTotalPages(totalPages)
                     }
                 }
                 else {
                     setEnquireListData([])
                     setIsLoadCart(false)
                 }
             }
                 setActiveCheckId("")
 
             }
         } catch {
             setIsLoadCart(false)
             setActiveCheckId("")
             console.log("error");
         }
 
     }

     const clientActivity = () => {
        let path = window.location.pathname.split("/");
        // const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
        
            payload = {
                "clientid": localStorage.getItem("clientid"),
                "pagename": 'CLIENT ENQUIRES',
                "pageurl": window.location.href,
                "starttime": presentTime,
                "useragent": JSON.parse(localStorage.getItem("loc"))

            };

        
        let urlLink;
        if(localStorage.getItem("previousid")){
            urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        }else {
            urlLink= lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if(response.data.statusCode === 200){
                    localStorage.setItem("previousid",response.data.result)
                }
            });
    }
 
 
 
     const handleChangePages = (e) => {
         let name = e.target.name;
         let value = e.target.value;
         if (name == 'perPage') {
             setPageNumber(1)
             setPerpage(value)
         }
         if (name == 'pageNo') {
             setPageNumber(value)
         }
     }
     const previousClick = (e) => {
        e.preventDefault()
        let k = parseInt(pageNumber)

        setPageNumber(k - 1)
    }
    const nextClick = (e) => {
        e.preventDefault()
        let k = parseInt(pageNumber)
        setPageNumber(k + 1)
    }
 
  

     const handleBack = () => {
         history.push('/content')
     }
 
     const handleExplore = () => {
        setShowCategoryData(true)
         history.push('/content')
     }
 
   
    
       
     const handleViewEnquire = (e,item)=> {
      history.push('/viewenquire/'+item.enquiryid)
     }
     const handleShow = (e,value) => {
        setShowAdditionalComm(true)
        setActiveCommentsData(value)

    }
    const handleClose = () => {
        setActiveCommentsData("")
        setShowAdditionalComm(false);
      };
     return (
         <>
           {inactiveFlag && <InactivePopup />}
            {!inactiveFlag && showSessionPopupup && <SessionPopup />}

              {/* {showSessionPopupup && <SessionPopup />} */}
           
             <InsideHeader activeClass={"2"} />
             <Modal show={ShowAdditionalComm} onHide={handleClose} className="modal title-des-popup order_comments">
                    <Modal.Header closeButton>
                        <Modal.Title>COMMENTS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p style={{ whiteSpace: "pre-line" }}>{activeCommentsData}</p>

                    </Modal.Body>


                </Modal>
             <div className="inner-body cart orders">
                 <div className="body-header">
                     <div className="md-container">
                         <div className="page-header">
                         <div className="title-wrapper">
                             <div className="block-title">
                                 <h2>orders</h2>
                             </div>
                             {/* <div className="title-action">
                            
 
                            </div> */}
                                          
                             </div>
                             <button className="plain-btn" onClick={handleBack}><span className="material-icons-outlined">chevron_left
                             </span>Back</button>
 
                         </div>
                     </div>
                 </div>
              
                 {enquireListData && enquireListData.length > 0 ? (<>
 
 
                     <div className="body-content mt-3">
                         <div className="md-container">
                             <div className="cart-block mb-3">
                                 <table className="table">
                                     <thead>
                                         <tr>
                                             <th>No of Titles Enquired</th>
                                             <th>Date of Enquiry<span className="material-icons-outlined">filter_list</span></th>
                                             <th>Comments</th>
                                             <th>Status<span className="material-icons-outlined">filter_list</span></th>
                                             <th>Actions</th>
                                         </tr>
                                     </thead>
 
                                     <tbody>
                                     
                                      {enquireListData && enquireListData.length > 0 && enquireListData.map((eachItem,i)=>{

                                        return(
                                          <tr key={i}>
                                          <td>{`${eachItem.content.length===1 ? '1 Title':eachItem.content.length > 1 ? eachItem.content.length+' Titles' : null }`}</td>
                                          <td>{moment(eachItem.created).format('DD-MMM-YY')}</td>
                                          <td><div className="cast">
                                            
                                            {/* {eachItem.comments} */}
                                            {eachItem?.comments ? eachItem?.comments.substring(0, 100) + " ":""}
                                                                               {eachItem?.comments?.length > 100 ?
                                                                               
                                                                               (<button className="more-popup-btn"
                                                                                 onClick={e => handleShow(e,eachItem?.comments)}>More</button>
                                                                               ) : null}
                                          
                                          </div></td>
                                          <td>{eachItem.status === undefined || eachItem.status === "" ? "NEW" : eachItem.status}</td>
                                          <td><span className="material-icons" style={{cursor:'pointer'}} onClick={(e)=>handleViewEnquire(e,eachItem)}>visibility</span></td>
                                          </tr>
                                        )
                                      })}
                                   
                              
                                     </tbody>
                                 </table>
                                
 
                             </div>
 
                             {assetTotal > perPageConst ?
                                 <div className="row pagenation pgn">
                                     <div className="pagenation-left d-flex align-items-center">
                                         <label className="me-5">Showing {(pageNumber * perpage) >= assetTotal ? assetTotal : (pageNumber * perpage)} of {assetTotal}</label>
                                         <div className="pagenation_page"> <label>Page :</label>
                                             {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                             <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" value={pageNumber} name="pageNo" onChange={e => handleChangePages(e)}>
                                                 {totalPagesArray && totalPagesArray.map(function (item, i) {
                                                     return (
                                                         <option key={i} value={item}>{item}</option>
                                                     )
                                                 })}
                                             </select>
                                             <label><strong> of {totalPages}</strong></label>
                                         </div>
                                     </div>
                                     <div className="pagenation-right d-flex align-items-center">
                                         <div className="pagenation_perpage">
                                             <label>Per Page:</label>
                                             {/* <span className="material-icons-outlined">arrow_drop_down</span> */}
                                             <select className="form-control custom-select u-w60 custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" name="perPage" value={perpage} onChange={e => handleChangePages(e)} >
 
                                                 <option value={10}>10</option>
                                                 <option value={20}>20</option>
                                                 <option value={30}>30</option>
                                                 <option value={40}>40</option>
                                                 <option value={50}>50</option>
                                             </select>
                                         </div>
                                         <div className="pagenation-buttons d-flex align-items-center">
                                             <button className="jr-btn btn-white btn btn-default btn-block" disabled={pageNumber == 1} onClick={e => previousClick(e)}><span className="material-icons-outlined">chevron_left</span></button>
                                             <button className="jr-btn btn-white btn btn-default btn-block" disabled={pageNumber == totalPages} onClick={e => nextClick(e)} ><span className="material-icons-outlined">navigate_next</span></button>
                                         </div>
                                     </div>
                                 </div>
 
                                 : null}
                         </div>
                     </div>
 
                 </>) : isLoadCart ? (<>
                     <div className="orasi-preloader">
                         <img src={img+"orasi/common/images/preloader.png"} />
                     </div>
                 </>) : (<>
                     <div className="comingsoon-wrapper">
                         <div className="comingsoon-block">
                             <img src={img+"orasi/common/images/shopping-list.png"} />
                             <h6>Your orders are empty!</h6>
                             <p>Looks like you have not added anything to your cart.<br />Go ahead and explore top categories.</p>
                             <button className="fill_btn yellow-gradient mt-2" onClick={handleExplore}>
                                 Explore Now
                             </button>
                         </div>
                     </div>
                 </>)}
 
             </div>
             <Footer menus={menus} />
             <ToastContainer
                 position="bottom-center"
                 autoClose={3000}
                 hideProgressBar={false}
                 newestOnTop={false}
                 closeOnClick
                 rtl={false}
                 pauseOnFocusLoss
                 draggable
                 pauseOnHover={false}
                 theme="light"
             />
 
         </>
     );
 };
 
 export default Enquire;