/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import tmdbApi from "../../api/tmdbApi";
import { useHistory } from "react-router";
import * as Config from "../../constants/Config";
import Footer from "../../components/footer/Footer";
import { useParams } from 'react-router-dom';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal';
import ReactHtmlParser from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { contentContext } from "../../context/contentContext";
import moment from "moment";
import SessionPopup from "../SessionPopup"
import FileViewer from "../docViewer";
import Slider from "react-slick";
import Loader from "../../components/loader";
import Select from 'react-select';
import BidTerms from "./bidterms";
let { lambda, appname } = window.app;


const Bids = () => {
    let { id } = useParams();
    const [config, setConfig] = useState({});
    const [info, setInfo] = useState({});
    const [resetKey, setResetKey] = useState(0);
    const [image, setImg] = useState('');
    // const [contentImageData, setContentImageData] = useState([]);
    //const [menus, setMenus] = useState([]);
    const history = useHistory();
    const [type, setType] = useState(false);
    const [show, setShow] = useState(false);
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [errors, setErrors] = useState({});
    const [toggle, setToggle] = useState(false);
    const [wishLoad, setWishLoad] = useState(false);
    const [ShowErrMsg, setShowErrMsg] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showDoc, setshowDoc] = useState(false);
    const location = useLocation();
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [countryData, setCountryData] = useState({});
    const [regionsData, setRegionsData] = useState({});
    const [typesofrights, setTypesofrights] = useState([]);
    const [exclusivityData, setExclusivityData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [revenueList, setRevenueList] = useState([]);
    const [selected, setSelected] = useState({});
    const [alllookup, setAllLookUp] = useState({});
    const [lookup, setLookUp] = useState({});
    const [payload, setPayload] = useState({});
    const [combinationData, setCombinationData] = useState([]);
    const [totalMinBidPrice, setTotalMinBidPrice] = useState(0);
    const [isGroup, setIsgroup] = useState(false);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [isBreakUp, setIsBreakUp] = useState(false);
    const [loader, setLoader] = useState(false);
    const [flag, setFlag] = useState(false);

    const [alertPopup, setAlertPopup] = useState(false);
    const [showReset, setShowReset] = useState(false);

    const [bidObject, setBidObject] = useState([]);
    const [bidname, setBidName] = useState("");
    const [perioderror, setPeriodError] = useState("");
    const [priceerror, setPriceError] = useState("");
    const [combbidpriceerror, setCombBidPriceError] = useState("");
    const [combindex, setIndex] = useState([]);
    const [bidData, setBidData] = useState({ period: 1 });
    const [countryCombination, setCountryCombination] = useState([]);

    const [configFlag, setConfigFlag] = useState(true);

    const [combpopup, setCombPopup] = useState(false);
    const [existPopup, setExistPopup] = useState(false);
    const [errormsg, setErrorMSg] = useState("");
    const [oldInfo, setOldInfo] = useState({});
    const [oldprice, setOldPrice] = useState({});
    const { menus, setActiveTab, terms, setTerms } = useContext(contentContext);



    useEffect(() => {
        localStorage.removeItem("check");

        // getWishlistFun();

        if (localStorage.getItem("clientid") != null) {
            getItem();
            clientActivity();
            GetLookUp();
        }

    }, []);

    useEffect(() => {
        if (window.site) {
            setConfigFlag(false)
            setConfig(window.site);
        }
        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }
    }, [window.site]);




    if (config.common && config.common.imagesCDN) {
        var img = config.common.imagesCDN;
    }

    if (config.common && config.common.proxiesCloudFront) {
        var proxyUrl = config.common.proxiesCloudFront
    }

    const GetLookUp = async (e) => {
        try {
            let arrayType = ["country", "rights", "region", "exclusivity"];

            const response = await tmdbApi.getBiddingLookUp({
                type: arrayType

            });
            // console.log('response here', response);
            if (response.statusCode == 200) {
                let arrayType = ["country", "rights", "region", "exclusivity"];

                let lookupsData = response.result || []
                setAllLookUp(lookupsData);

                //  console.log("response ", response)
                arrayType.map((eachItem) => {
                    let arr = []
                    // let rightsComplete = []
                    lookupsData.map((item) => {
                        if (item.type == eachItem) {
                            arr.push(item.name)
                            if (item.type == "rights") {
                                console.log("item ", item)
                                setRevenueList(revenueList => ([
                                    ...revenueList,
                                    item
                                ]));

                            }

                        }
                    });
                    lookup[eachItem] = arr
                    // lookup["rightsfull"] = rightsComplete
                    setLookUp({ ...lookup });
                })
                //  let arrayType = ["country","genre","videoformat","resolution","musicgenre","rights","cuisine","sports","certificate"];

                const countryObj = lookup.country.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setCountryData(countryObj)
                console.log("countryObj", revenueList)
                const regionsObj = lookup.region.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setRegionsData(regionsObj)
                const rightsObj = lookup.rights.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                // console.log("rightsObj ", rightsObj)
                setTypesofrights(rightsObj)
                const exclusivityObj = lookup.exclusivity.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                setExclusivityData(exclusivityObj)
            }
        } catch {
            console.log("error");
        }
    }
    // console.log("lookupsData", alllookup)
    const returnArray = (arr) => {
        let arr2 = []
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }
    const handleChangeMultiSelectCategory = (selected, key) => {
        if ((key === "country") || (key === "region")) {
            delete errors["country"]
            delete errors["region"]
        }
        if (!!errors[key]) {
            let error = Object.assign({}, errors);
            delete error[key];
            setErrors(error);
        }

        if (key === "typesofrights") {
            if (selected === null) {
                const updatedObject = { ...payload };
                delete updatedObject[key];
                setPayload(updatedObject);
            } else {
                const rightsObj = revenueList.filter((eachItem) => { return eachItem.name == selected.value })
                console.log("rightsObj ", rightsObj)
                if (rightsObj && rightsObj.length > 0 && rightsObj[0].revenue) {
                    const revenueObj = rightsObj[0].revenue.map((eachItem) => { return { value: eachItem, label: eachItem }; })
                    setRevenueData(revenueObj)
                } else {
                    setRevenueData([])
                }
                setPayload({ ...payload, [key]: selected.value });
            }

        } else if (key === "revenuetype") {
            if (selected === null) {
                const updatedObject = { ...payload };
                delete updatedObject[key];
                setPayload(updatedObject);
            } else {
                setPayload({ ...payload, [key]: selected.value });
            }
        } else if (key === "exclusivity") {
            if (selected === null) {
                const updatedObject = { ...payload };
                delete updatedObject[key];
                setPayload(updatedObject);
            } else {
                setPayload({ ...payload, [key]: selected.value });
            }
        } else if (key === "country") {
            let selectedArray = returnArray(selected)
            const filteredObjects = alllookup.filter((obj) => selectedArray.includes(obj.name));
            console.log("filteredObjects", filteredObjects)
            const commonValueExists = filteredObjects && filteredObjects.length > 0 && filteredObjects.some((obj) =>
                obj.region?.some((region) => payload && payload.region?.includes(region))
            );
            console.log("commonValueExists--------->", commonValueExists)
            if (commonValueExists === true) {
                selectedArray.pop();
                setExistPopup(true)
                console.log("already region selected");
                console.log("selectedArray", selectedArray)

            }
            else {
                console.log("selectedArray", selectedArray)
                setPayload({ ...payload, [key]: selectedArray })
            }
        } else if (key === "region") {
            let selectedArray = returnArray(selected)
            console.log("selectedArray", selectedArray)
            setPayload({ ...payload, [key]: selectedArray })

        } else {
            let selectedArray = returnArray(selected)
            console.log("selectedArray", selectedArray)
            setPayload({ ...payload, [key]: selectedArray })
        }
    }



    const getItem = (e) => {
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        // console.log('clientIdclientId===>',clientId)
        // console.log('tokentoken===>',token)
        let url = ""
        if (clientId != null && token != null) {
            if (localStorage.getItem("url")) {
                url = lambda + '/biddingItems?appname=' + appname + '&itemid=' + id + "&clientid=" + clientId + "&token=" + token + "&showoffline=true"
            } else {
                url = lambda + '/biddingItems?appname=' + appname + '&itemid=' + id + "&clientid=" + clientId + "&token=" + token
            }
        }

        axios({
            method: 'GET',
            url: url
        })
            .then(function (response) {
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }
                else if (response.data.result != "No Titles Data") {
                    if (response.data.result[0].status === "ACTIVE") {
                        setInfo(response.data.result[0]);
                    } else {
                        setShowErrMsg(true)
                    }


                }
                else {
                    console.log('no content data')
                    setShowErrMsg(true)
                }
            });
    }

    // console.log('clientData', clientData)
    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 2];
        var presentTime = moment();
        let payload;

        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": "BID NOW",
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }

    const handleSubmit = async (e) => {
        const isValid = validateMandatoryFields();
        if (isValid) {
            handleSearch();
        }
    }
    const validateMandatoryFields = () => {
        let formIsValid = true;

        let error = { ...errors }
        let k;
        if (payload && payload.country && payload.country.length > 0) {
            k = [{ name: 'Country', key: 'country' },
            // { name: 'Types of rights', key: 'typesofrights' },
            // { name: 'Revenue Type', key: 'revenuetype' },
            { name: 'Exclusivity', key: 'exclusivity' }
            ];
        } else if (payload && payload.region && payload.region.length > 0) {
            k = [{ name: 'Region', key: 'region' },
            // { name: 'Types of rights', key: 'typesofrights' },
            // { name: 'Revenue Type', key: 'revenuetype' },
            { name: 'Exclusivity', key: 'exclusivity' }
            ];
        } else {
            k = [{ name: 'Country', key: 'country' }, { name: 'Region', key: 'region' },
            // { name: 'Types of rights', key: 'typesofrights' },
            // { name: 'Revenue Type', key: 'revenuetype' },
            { name: 'Exclusivity', key: 'exclusivity' }
            ];
        }
        console.log("k", k)
        if (k) {
            k.forEach(function (item) {
                if (item.key === "revenuetype" && revenueData && revenueData.length <= 0) {

                }
                else if (payload[item.key] == "" || payload[item.key] == undefined || payload[item.key] == "undefined") {
                    error[item.key] = "please select " + item.name;
                    formIsValid = false;
                }
            })
        }

        console.log("formIsValid", formIsValid)
        console.log("formIsValid", error)
        setErrors(error);
        return formIsValid;

    }
    const handleSearch = async (e) => {
        setPriceError("");
        setPeriodError("");

        if (combinationData && combinationData.length <= 0) {
            setCombinationData([])
            onSearch();
        } else {
            setAlertPopup(true)
        }
    }
    const onSearch = async (e) => {
        setBidData({ ...bidData, period: 1, bidprice: 0 })
        const updatedData = combinationData.map(obj => ({
            ...obj,
            bidprice: 0,
            // You can update other keys as well if needed
        }));
        setCombinationData(updatedData);

        setLoader(true)
        setAlertPopup(false)
        setCombinationData([])
        setLoaderEnable(true)
        if ((payload && payload.region === "") || (payload && payload.region === undefined) || (payload && payload.region.length <= 0)) {
            delete payload["region"];
        }
        if ((payload && payload.typesofrights === "") || (payload && payload.typesofrights === undefined) || (payload && payload.typesofrights.length <= 0) || (typesofrights && typesofrights.length <= 0)) {
            delete payload["typesofrights"];
        }
        if ((payload && payload.revenuetype === "") || (payload && payload.revenuetype === undefined) || (payload && payload.revenuetype.length <= 0) || (revenueData && revenueData.length <= 0)) {
            delete payload["revenuetype"];
        }
        if ((payload && payload.country === "") || (payload && payload.country === undefined) || (payload && payload.country.length <= 0)) {
            delete payload["country"];
        }
        setBidObject(payload);
        const response = await tmdbApi.getCombinationList(id, payload)
        setLoaderEnable(false);
        setLoader(false)

        let result = response.result.data;


        if (response.result == "Invalid token or Expired") {
            setShowSessionPopupup(true)
        } else if ((response.result === "You have already bidded for this combination. Please search another combination") || (response.result === "This combination already Approved. Please try for another combination") || (response.result === "This combination already Awarded. Please try for another combination") || (response.result === "This combination already Withdraw. Please try for another combination")) {
            setErrorMSg(response.result);
            setCombPopup(true);
        } else {
            if (response.result.data.length <= 0) {
                setFlag(true)
            }
            if (response.result.data.length > 0) {
                setSearchData({ ...payload })

                if (result.length > 1) {

                    setIsgroup(true)
                    const sumOfMinBidPrices = result.reduce((total, obj) => {
                        return total + (obj.minbidprice || 0); // Use 0 if minbidprice is missing or undefined
                    }, 0);


                    setTotalMinBidPrice(sumOfMinBidPrices)
                }else if(payload.region && result.length ===1){
                    setIsgroup(true);
                    const sumOfMinBidPrices = result.reduce((total, obj) => {
                        return total + (obj.minbidprice || 0); // Use 0 if minbidprice is missing or undefined
                    }, 0);
                    setTotalMinBidPrice(sumOfMinBidPrices)
                } else {
                    setIsgroup(false)
                    setTotalMinBidPrice(result[0].minbidprice)
                }
                if (payload && payload.country) {
                    const newArray = result.map(obj => obj["country"]);
                    setCountryCombination(newArray);

                }
                setCombinationData(response.result.data)
            }
        }
    }
    const handleDataChange = (e, key) => {
        if (key === "period") {
            setPeriodError("")
        }
        if (key === "bidprice") {
            setPriceError("")
        }
        setBidData({ ...bidData, [key]: parseInt(e.target.value) });
    }
    console.log("biddata", bidData);

    const handleChange = (e, key, index) => {
        setPriceError("")
        let updatedObject = [...combinationData]
        console.log("combinationData", combinationData)
        if (key === "bidprice") {
            updatedObject[index][key] = parseInt(e.target.value);
        }

        setCombinationData(updatedObject)
        //console.log("updatedObject",updatedObject);

    }

    const handleClear = () => {
        setErrors({})
        setFlag(false);
        setResetKey((prevKey) => (prevKey + 1) % 2);
        setPayload({ ...payload, country: '', typesofrights: "", revenuetype: "", exclusivity: "", region: "" })
    }
    const handleCloseErr = () => {
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        if (clientId != null && token != null) {
            history.push('/auctionmoreinfo/' + id)
            setActiveTab("title")
           
        } else {
            setActiveTab("title")
            history.push('/')
        }
        setShowErrMsg(false)
    }

    const handleBid = () => {
        let myBidname=[]
        if(payload.country){
            const countriesWithZeroBid = combinationData.reduce((acc, item) => {
                if (!acc[item.country]) {
                    acc[item.country] = { allZero: true, bidPrices: [] };
                }
                acc[item.country].bidPrices.push(item.bidprice);
                if (item.bidprice !== 0 && !isNaN(item.bidprice)) {
                    acc[item.country].allZero = false;
                }
                return acc;
            }, {});
            console.log("countriesWithZeroBid",countriesWithZeroBid)
            
            const result = Object.keys(countriesWithZeroBid).filter(country => countriesWithZeroBid[country].allZero);
            const newArray = payload.country.filter(item => !result.includes(item));
            console.log("new",newArray);
            if(payload.region && newArray.length >= 0){
                myBidname = [...newArray,...payload.region]
            }else{
                myBidname = newArray;
            }
    }else if(payload.region){
        myBidname= payload.region
    }
        console.log(bidData.period)
        if ((bidData.period >= 100) || (bidData.period < 1) || (bidData.period === undefined) || (bidData.period === NaN)) {
            console.log("came")
            setPeriodError("Please Enter Valid Period");
        } else if (bidData.bidprice < totalMinBidPrice) {
            setPriceError("Please Enter bid price more than minimum bid price");
        } else {
            let Obj;
            if (combinationData.length === 1) {
                Obj = {
                    "bidname": combinationData[0].country,
                    "bidprice": combinationData[0].bidprice,
                    "title": info.title,
                    "itemcombinationid": combinationData[0].combinationid,
                    "country": combinationData[0].country,
                    "region": combinationData[0].region != undefined ? combinationData[0].region?.toString() : "",
                    "typesofrights": combinationData[0].typesofrights,
                    "revenuetype": combinationData[0].revenuetype != undefined ? combinationData[0].revenuetype : "",
                    "exclusivity": combinationData[0].exclusivity,
                    "period": combinationData[0].period,
                    "minbidprice": combinationData[0].minbidprice,
                    "isgroup": false,
                    "itemimage": info.itemimage,
                }
            } else {


                const newArray = combinationData
                .filter(oldObj => (oldObj.bidprice > 0 || oldObj.bidprice != undefined) )  // Filter out objects where bidprice is not greater than 0
                .map(oldObj => {
                    // Select specific key-value pairs from the old object
                    const { bidprice, country, region, revenuetype, typesofrights, exclusivity, period, minbidprice } = oldObj;
            
                    // Add new key-value pairs to the new object
                    const itemcombinationid = oldObj.combinationid;
                    const bidname = oldObj.country;
                    const title = info.title;
                    const itemimage = info.itemimage;
            
                    return { bidname, title, itemimage, bidprice, country, region, typesofrights, revenuetype, exclusivity, period, minbidprice, itemcombinationid };
                });

                   console.log("newArray",newArray);
              

                Obj = {
                    "bidname": myBidname.toString(),
                    "bidprice": bidData.bidprice,
                    "title": info.title,
                    "region": bidObject.region?.toString(),
                    "typesofrights": bidObject.typesofrights,
                    "revenuetype": bidObject.revenuetype != undefined ? bidObject.revenuetype : "",
                    "exclusivity": bidObject.exclusivity,
                    "period": bidData.period,
                    "minbidprice": totalMinBidPrice,
                    "itemimage": info.itemimage,
                    "isgroup": true,
                    "bids": newArray,
                }

            }
            let checkcondition = false;
            combinationData.forEach(async (item) => {
                // Check if bidprice is greater than 0
                if (item.bidprice > 0) {
                    // Further condition checks
                    if ((item.bidprice <= item.minbidprice) || isNaN(item.bidprice)) {
                        checkcondition = true;
                    }
                }
            });
            console.log("checkcondition",checkcondition)
            if (checkcondition === true) {
                setPriceError("Please Enter bid price more than minimum bid price for all combinations");
            } else {

                console.log("payload", payload)
                if(combinationData.length > 1)
                Obj.bids = Obj.bids.filter(bid => bid.bidprice !== 0 && bid.bidprice !== undefined && !isNaN(bid.bidprice));
                console.log("Obj", Obj)

                localStorage.setItem("payload", JSON.stringify([Obj]));
                localStorage.setItem("itemid", id);
                //history.push("/bidterms")
                setTerms(true)
           }

        }
    }
    const handleBreak = () => {

        setIsBreakUp(true);


    }
    const handleKeyDown = (event) => {
        // Prevent the minus sign from being entered
        if (event.key === '-' || event.key === '–') {
            event.preventDefault();
        }
    };

    const handleBreakClose = () => {
        setIsBreakUp(false)
    }
    const checkCombinations = () => {
        const itemsWithBidprice = combinationData.filter(item => item.bidprice !== undefined && item.bidprice !== null && item.bidprice !== 0);
       console.log("itemsWithBidprice",itemsWithBidprice)
        if (itemsWithBidprice.length === 0) {
            return true;
        } else {
            return false;
        }
    }
    console.log("old",oldInfo)
    const handleCheckBidPrice = () => {
        setIndex([])
        if (bidData.bidprice === undefined) {
            setPriceError("Please Enter bid price");
        } else if (bidData.period <= 0) {
            setPeriodError("Please Enter Valid Period");
        }
        else if (bidData.bidprice < totalMinBidPrice) {
            setPriceError("Please Enter bid price more than minimum bid price");

        }
        if (bidData.bidprice || bidData.period) {
            let key = checkCombinations();
            console.log("key",key)
            let updatedData;
            if (key) {
                updatedData = combinationData.map(obj => ({
                    ...obj,
                    bidprice: Math.round((obj.minbidprice / totalMinBidPrice) * bidData.bidprice),
                    period: bidData.period
                    // You can update other keys as well if needed
                }));
                setOldInfo(updatedData);
                setOldPrice(bidData.bidprice);
            } else {
                const allObjectsContainKey = combinationData.every(obj => obj.hasOwnProperty("bidprice"));
                if(allObjectsContainKey){
                updatedData = combinationData.map((obj, index) => ({
                    ...obj,
                    bidprice: Math.round((oldInfo[index].bidprice / oldprice) * bidData.bidprice),
                    period: bidData.period,
                    index: index  // Adding the index if needed
                    // You can update other keys as well if needed
                }));
            }else{
                updatedData = combinationData.map(obj => ({
                    ...obj,
                    bidprice: Math.round((obj.minbidprice / totalMinBidPrice) * bidData.bidprice),
                    period: bidData.period
                    // You can update other keys as well if needed
                }));
            }
                setOldInfo(updatedData);
                setOldPrice(bidData.bidprice);
            }
            // console.log("updatedData",updatedData);
            const sumOfMinBidPrices = updatedData.reduce((total, obj) => {
                return total + (obj.bidprice || 0); // Use 0 if minbidprice is missing or undefined
            }, 0);

            let cummulative = bidData.bidprice - sumOfMinBidPrices;
            if (cummulative > 0) {
                const newArray = [...updatedData];
                const lastObject = newArray[newArray.length - 1];
                lastObject["bidprice"] += cummulative;
                setCombinationData(newArray);
            }
            else if (cummulative < 0) {
                const newArray = [...updatedData];
                const lastObject = newArray[newArray.length - 1];
                lastObject["bidprice"] += cummulative;
                setCombinationData(newArray);
            } else {
                setCombinationData(updatedData);
            }

            console.log("bidData.bidprice", bidData.bidprice, sumOfMinBidPrices)
            const sumOfMinBidPrices1 = updatedData.reduce((total, obj) => {
                return total + (obj.minbidprice || 0); // Use 0 if minbidprice is missing or undefined
            }, 0);
            if (bidData.bidprice >= sumOfMinBidPrices1) {
                setCombBidPriceError("")
            }


        }

    }
    const handleCalculateTotal = (e, index) => {
        if ((combinationData[index].minbidprice) > (combinationData[index].bidprice)) {
            //setIndex(index)
            combindex.push(index)
            setCombBidPriceError("Please Enter bid price more than minimum bid price")
        } else {
            if (combindex.includes(index)) {
                const newArray = combindex.filter(item => item !== index);
                setIndex(newArray)
            } else {
                setCombBidPriceError("")
            }

        }
        console.log("combindex", combindex)
    }
    const handleCheckTotal = (e) => {
        const sumOfMinBidPrices = combinationData.reduce((total, obj) => {
            return total + (obj.bidprice || 0); // Use 0 if minbidprice is missing or undefined
        }, 0);
        console.log("totalMinBidPrice", totalMinBidPrice, sumOfMinBidPrices, totalMinBidPrice < sumOfMinBidPrices)
        if (totalMinBidPrice < sumOfMinBidPrices) {
            setCombBidPriceError("");
            setPriceError("")
        }

        setBidData({ ...bidData, bidprice: sumOfMinBidPrices })


    }
    const handleShowReset = () => {
        setShowReset(true);
    }
    const handleReset = () => {
        //  setCombinationData([]);
        setBidData({ ...bidData, period: 1, bidprice: 0 })
        const updatedData = combinationData.map(obj => ({
            ...obj,
            bidprice: 0,
            period: 1,
            // You can update other keys as well if needed
        }));
        setCombinationData(updatedData);
        setCombBidPriceError("");
        setPriceError("");
        setPeriodError("");
        setShowReset(false)
    }

    const onConfirm = (e) => {
        setCombPopup(false);
        handleClear();
    }
    const onConfirm1 = (e) => {
        setResetKey((prevKey) => (prevKey + 1) % 2);
        setExistPopup(false);
    }
    const getBidName = (e) => {
        if (!searchData.region || searchData.region.length === 0) {
            return searchData.country || [];
        } else if (!searchData.country || searchData.country.length === 0) {
            return searchData.region;
        }
            console.log("searchData",searchData)
   // If both arrays have data, merge them.
   return [...searchData.region, ...searchData.country];
    
     

    }
    const handleCloseErr1 = () => {
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        console.log('close')
        if (clientId != null && token != null) {
            history.go(-1)
        } else {
            history.push('/')
        }
        setShowErrMsg(false)
    }

    const handleClose1 = (e) => {
        e.preventDefault();
        history.push("/auction")
    }
    const cardWidth = $(".title-thumb").width();

    let defaultImg = `${img}orasi/common/images/img-default.jpg`;

    let imgUrl = info && info.itemimage ? img + info.itemimage : defaultImg


    console.log("payload", payload.country)
    // console.log("bidObject", bidObject)
    console.log("combinationData", combinationData)
    return (
        <>
            <Modal className="access-denied" show={ShowErrMsg}>

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={(e) => handleCloseErr1(e)}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">error</span>
                        <h3>Content Not Found</h3>



                    </div>
                </div>

            </Modal>
            <Modal className="access-denied" show={alertPopup} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => setAlertPopup(false)}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">https</span>
                        <h3>Confirmation</h3>
                        <p>This action cannot be undone. Do you want to Proceed?</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={onSearch}>{BtnLoader ? (<img src={img+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}continue</button>
                    </div>
                </div>

            </Modal>
            <Modal className="access-denied another-combination" show={combpopup} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => onConfirm()}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">https</span>

                        <p>{errormsg}</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>close</button>
                    </div>
                </div>

            </Modal>
            <Modal className="access-denied another-combination" show={existPopup} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => onConfirm1()}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">https</span>

                        <p>This country already exists in region. Please select another country</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm1()}>close</button>
                    </div>
                </div>

            </Modal>
            <Modal className="access-denied" show={showReset} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => setShowReset(false)}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">https</span>
                        <h3>Confirmation</h3>
                        <p>This action cannot be undone. Do you want to Proceed?</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={handleReset}>{BtnLoader ? (<img src={img+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}continue</button>
                    </div>
                </div>

            </Modal>
            {showSessionPopupup && <SessionPopup />}
            <>
                <>
                    {configFlag ? <Loader /> : terms ? <BidTerms /> :
                        <div className="asset-container bid_details" style={{ "background": "#efefef" }}>
 {/* <button type="button" onClick={handleClose1} className="btn"> close </button> */}
                            <div className="asset-container-header">
                                <h2>{info.title}</h2>
                                <a onClick={(e) => handleCloseErr(e)} className="material-icons-outlined close"> close </a>
                            </div>


                            <>

                                <div className="asset-details-wrapper">

                                    <div className="left-block">
                                        <div className="asset">
                                            <div className="img">
                                                <img src={imgUrl.toString() + "?auto=compress,format&width=" + cardWidth} alt="" />
                                            </div>
                                            <div className="info">
                                                {/* <h2>{info.title}</h2> */}
                                                <h3>AVAILABlLITY</h3>
                                                <p>{info.availability}</p>

                                            </div>
                                        </div>
                                        <h2 className="sub_title">SEARCH COMBINATIONS </h2>
                                        <div className="form-group">
                                            <label for="example-text-input" className="col-form-label">Select Regions*</label>
                                            <Select isMulti={true}
                                                key={resetKey}
                                                placeholder='Select regions'
                                                // isClearable={true}

                                                onChange={(e) => handleChangeMultiSelectCategory(e, "region")}

                                                options={regionsData}
                                                // isDisabled={payload && payload.country && payload.country.length > 0 ? true : false}

                                                value={payload && payload.region && payload.region.length > 0 && payload.region?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                            />
                                            {errors.region && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.region}</span>}
                                        </div>

                                        <p className="or-divider"><span className="or">or</span></p>
                                        <div className="form-group">
                                            <label for="example-text-input" className="col-form-label">Select Countries*</label>
                                            <Select isMulti={true}
                                                key={resetKey}
                                                placeholder='Select country'
                                                isClearable={true}
                                                onChange={(e) => handleChangeMultiSelectCategory(e, "country")}
                                                //  isDisabled={payload && payload.region && payload.region.length > 0 ? true : false}

                                                options={countryData}
                                                value={payload && payload.country && payload.country.length > 0 ? payload.country?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}
                                            />
                                            {errors.country && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.country}</span>}
                                        </div>


                                        <div className="form-group">
                                            <label for="example-text-input" className="col-form-label">Types of Rights</label>

                                            <Select isMulti={false}
                                                key={resetKey}
                                                placeholder='Select types of rights'
                                                isClearable={true}
                                                onChange={(e) => handleChangeMultiSelectCategory(e, "typesofrights")}
                                                options={typesofrights}
                                                value={payload && payload.typesofrights && payload.typesofrights.length > 0 && payload.typesofrights.split(",")?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                            />
                                            {errors.typesofrights && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.typesofrights}</span>}
                                        </div>

                                        {revenueData && revenueData.length > 0 &&
                                            <div className="form-group">
                                                <label for="example-text-input" className="col-form-label">Revenue Type</label>
                                                <Select isMulti={false}
                                                    key={resetKey}
                                                    placeholder='Select Revenue Type'
                                                    isClearable={true}
                                                    onChange={(e) => handleChangeMultiSelectCategory(e, "revenuetype")}
                                                    options={revenueData}
                                                    value={payload && payload.revenuetype && payload.revenuetype.length > 0 && payload.revenuetype.split(",")?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                />
                                                {errors.revenuetype && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.revenuetype}</span>}
                                            </div>}


                                        <div className="form-group">
                                            <label for="example-text-input" className="col-form-label">Exclusivity</label>
                                            <Select isMulti={false}
                                                key={resetKey}
                                                placeholder='Select Exclusivity'
                                                isClearable={true}
                                                onChange={(e) => handleChangeMultiSelectCategory(e, "exclusivity")}
                                                options={exclusivityData}
                                                value={payload && payload.exclusivity && payload.exclusivity.length > 0 && payload.exclusivity.split(",")?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                            />
                                            {errors.exclusivity && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.exclusivity}</span>}
                                        </div>
                                        <button type="button" className="clear-btn line-btn" onClick={handleClear}>
                                            <span className="material-icons me-1">autorenew</span>reset</button>

                                        <button onClick={(e) => handleSubmit(e)} class="fill_btn yellow-gradient float-end ">{loaderEnable ? (<img src={img+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}SEARCH</button>
                                    </div>

                                    <div className="main-block">
                                        {combinationData && combinationData.length > 0 ?
                                            <><div className="list_hed">
                                                <h3>COMBINATIONS</h3>
                                            </div><div className="table-responsive"><table class="table table_bid table-striped">
                                                <thead>
                                                    <tr>
                                                        <th width="23%">COUNTRY/REGION</th>
                                                        <th width="12%" className="text-center">TYPES OF RIGHTS</th>
                                                        <th width="10%" className="text-center">REVENUE TYPE</th>
                                                        <th width="8%">EXCLUSIVITY</th>
                                                        <th width="9%" className="text-center">  Min Price (USD)
                                                        </th>

                                                        <th width="1%"> <div className="col_td">
                                                            <span className="field2">PEROID</span> <span className="field2">MY BID (USD)</span>
                                                        </div>  </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {combinationData && combinationData.length > 0 && combinationData.map((item, i) => {
  
                                                              return ( */}
                                                    <tr>
                                                        <td>{getBidName()?.join(", ")} {isGroup === true && <button onClick={handleBreak} className="breakup">View Breakup</button>}</td>
                                                        <td className="text-center">{searchData.typesofrights}</td>
                                                        <td className="text-center">{searchData.revenuetype}</td>
                                                        <td>{searchData.exclusivity}</td>
                                                        <td className="text-center">{totalMinBidPrice}</td>
                                                        <td>
                                                            <div className="col_td">

                                                                <div className="field">  <input type="number" onChange={(e) => handleDataChange(e, "period")} class="input input2" min="1"
                                                                    max="99" placeholder="1" onBlur={handleCheckBidPrice} value={bidData.period} onKeyDown={handleKeyDown} /></div>
                                                                <div className="field ml_10"><input type="text" class="input" placeholder="" value={bidData.bidprice ? bidData.bidprice : 0} onChange={(e) => handleDataChange(e, "bidprice")} onBlur={handleCheckBidPrice} onKeyDown={handleKeyDown} /></div>

                                                            </div>

                                                        </td>

                                                    </tr>
                                                    {/* );
                                                          })} */}
                                                </tbody>
                                            </table></div>
                                                {isGroup && isBreakUp && <><div className="list_hed mt-4">
                                                    <h3>BREAK UP</h3>
                                                    <button onClick={handleBreakClose} className="breakup" data-bs-toggle="modal" data-bs-target="#descriptionPopup"><span className="material-icons-outlined">close</span>CLOSE</button>
                                                </div><div className="table-responsive">
                                                        <table class="table table_bid table-striped break-up">
                                                            <thead>
                                                                <tr>
                                                                    <th width="12%">COUNTRY</th>
                                                                    <th width="11%">REGION</th>
                                                                    <th width="12%" className="text-center">TYPES OF RIGHTS</th>
                                                                    <th width="10%" className="text-center">REVENUE TYPE</th>
                                                                    <th width="8%">EXCLUSIVITY</th>
                                                                    <th width="9%" className="text-center">  MIN PRICE (USD)
                                                                    </th>

                                                                    <th width="1%"> <div className="col_td">
                                                                        <span className="field2">PEROID</span> <span className="field2">MY BID (USD)</span>
                                                                    </div>  </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {combinationData && combinationData.length > 0 && combinationData.map((item, i) => {

                                                                    return (
                                                                        <tr>
                                                                            <td>{item.country} </td>
                                                                            <td>{item.region && Array.isArray(item.region) ? item.region.join(', ') : ''} </td>
                                                                            <td className="text-center">{item.typesofrights}</td>
                                                                            <td className="text-center">{item.revenuetype}</td>
                                                                            <td>{item.exclusivity}</td>
                                                                            <td className="text-center">{item.minbidprice}</td>
                                                                            <td>
                                                                                <div className="col_td">

                                                                                    <div className=""> {item.period ? item.period : 1}</div>
                                                                                    <div className="field ml_10">   <input type="text" class="input" placeholder="" value={item.bidprice ? item.bidprice : 0} onChange={(e) => (handleChange(e, "bidprice", i), handleCalculateTotal(e, i))}
                                                                                        onBlur={(e) => handleCheckTotal(e)}
                                                                                        onKeyDown={handleKeyDown}
                                                                                    />
                                                                                        {/* <button><span className="material-icons-outlined">done</span></button> */}
                                                                                    </div>

                                                                                </div>
                                                                                {combindex.includes(i) && <span className="errormsg bidvalue" style={{ fontWeight: 'bold', color: 'red' }}>{combbidpriceerror}</span>}
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div></>}
                                                <div className="d-flex" style={{ flexDirection: "column" }}>
                                                    {perioderror && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{perioderror}</span>}<br /><br />
                                                    {priceerror && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{priceerror}</span>}
                                                </div>
                                                {/* <h3 className="sub_total">TOTAL ${bidData.bidprice}</h3> */}
                                                <p className="text-right">
                                                    <button class="fill_btn clear-btn" onClick={handleShowReset}> <span className="material-icons">autorenew</span>RESET</button>  {(bidData.bidprice && bidData.bidprice > totalMinBidPrice && bidData.period > 0) ? <button onClick={handleBid} class="fill_btn yellow-gradient float-end "> <span className="material-icons">arrow_outward</span> BID</button> : <button style={{ "opacity": 0.5 }} class="fill_btn yellow-gradient float-end "> <span className="material-icons">arrow_outward</span> BID</button>}
                                                </p></>
                                            : loader ? <Loader /> : <div className="main-block no_bids">
                                                <div className="info">
                                                    <div className="icon">
                                                        <span className="material-icons"> gavel </span>
                                                    </div>
                                                    {flag ? <p>No results found</p> : <p>Search for the Combination</p>
                                                    }
                                                </div></div>}
                                    </div>


                                    {/* <div className="main-block no_bids">
                                           <div className="info">
                                               <div className="icon">
                                                   <span className="material-icons"> gavel </span>
                                               </div>
                                               <p>No Bids yet. <br />
                                                   Search and add Bids</p>
                                           </div>
                                       </div> */}

                                </div>
                            </> :

                            <Footer menus={menus} moreInfoCls={"fixed-footer"} />

                        </div>
                    }
                </>


            </>








        </>
    );
};

export default Bids;
