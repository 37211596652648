import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import Modal from 'react-bootstrap/Modal';

const PlayerInfo = (props) => {
    const history = useHistory();
    const [player, setPlayer] = useState(null);
    const [show, setShow] = useState(true);
    const [playerKey, setPlayerKey] = useState('');

    useEffect(() => {
        const checkPlayerKey = () => {
            if (
                window.site &&
                window.site.client &&
                window.site.client.player &&
                window.site.client.player.bitmovin &&
                window.site.client.player.bitmovin.playerKey
            ) {
                setPlayerKey(window.site.client.player.bitmovin.playerKey);
            } else {
                console.error('Bitmovin player key not found in site configuration.');
            }
        };

        checkPlayerKey();
    }, []);

    const playerConfig = {
        key: playerKey,
        playback: {
            autoplay: true
        }
    };

    useEffect(() => {
        if (props.play && playerKey) {
            const proxyUrl = props.proxyUrl || '';
            const playerUrl = props.source?.video?.m3u8?.proxyUrl || '';
            const playerSource = {
                hls: playerUrl.startsWith("http") ? playerUrl : proxyUrl + playerUrl
            };

            setupPlayer(playerSource, props.subtitles);
        }
    }, [props.play, playerKey]);

    const setupPlayer = (playerSource, subtitles) => {
        setShow(true);

        const portalDiv = document.getElementById('player');
        if (!portalDiv) {
            throw new Error("The element #portal wasn't found");
        }

        const playerInstance = new Player(portalDiv, playerConfig);
        UIFactory.buildDefaultUI(playerInstance);
        
        playerInstance.load(playerSource)
            .then(() => {
                setPlayer(playerInstance);
                
                // Add subtitles if available
                subtitles?.forEach(subtitle => {
                    playerInstance.subtitles.add({
                        id: subtitle.uuid,
                        lang: subtitle.lang,
                        label: subtitle.label,
                        url: subtitle.path,
                        kind: "subtitle"
                    });
                });
                console.log('Successfully loaded source');
            })
            .catch(() => {
                console.error('Error while loading source');
            });
    };

    const destroyPlayer = () => {
        if (player) {
            player.unload();
            setPlayer(null);
            props.setPlay(false);
            setShow(false);
        }
    };

    return (
        <>
            <Modal show={show} className="video-popup">
                <button className="close-btn" onClick={destroyPlayer}>
                    <span className="material-icons">close</span>
                </button>
                <Modal.Body>
                    <div id="player" />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PlayerInfo;
