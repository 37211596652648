/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import tmdbApi from "../../api/tmdbApi";
import { useHistory } from "react-router";
import Footer from "../../components/footer/Footer";
import { useParams } from 'react-router-dom';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal';
import ReactHtmlParser from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { contentContext } from "../../context/contentContext";
import moment from "moment";
import SessionPopup from "../SessionPopup"

import Loader from "../../components/loader";


let { lambda, appname } = window.app;


const BidTerms = () => {
    let { id } = useParams();
    const [config, setConfig] = useState({});
    const [info, setInfo] = useState({});
    const [trailersData, setTrailersData] = useState([]);
    const [BTSData, setBTSData] = useState([]);
    const [clipsData, setClipsData] = useState([]);
    // const [contentImageData, setContentImageData] = useState([]);
    //const [menus, setMenus] = useState([]);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [playerSubTitles, setPlayerSubTitles] = useState('');

    const [toggle, setToggle] = useState(false);
    const [wishLoad, setWishLoad] = useState(false);
    const [ShowErrMsg, setShowErrMsg] = useState(false);
    const [showRequestPopup, setShowRequestPopup] = useState(false);
    const [isMailSentTwice, setIsMailSentTwice] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showDoc, setshowDoc] = useState(false);
    const location = useLocation();
    const [agree, setAgree] = useState(false);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [wish, setWish] = useState(false);
    const handleCloseWish = () => setWish(false);

    const [showSessionPopupup, setShowSessionPopupup] = useState(false);


    const [play, setPlay] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const [activeCheckId, setActiveCheckId] = useState("");

    const [playContent, setPlayContent] = useState({});
    const [configFlag, setConfigFlag] = useState(true);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [load, setLoad] = useState("");
    const [blockedmsg, setBlockedMsg] = useState(false);


    const { isShowMyContent, clientData, GetClientDataFunction, wishListData, setWishListData, menus,terms, setTerms } = useContext(contentContext);


    useEffect(() => {

        getTerms();
        if (localStorage.getItem("clientid") != null) {

            clientActivity();
        }

    }, []);
    useEffect(() => {

        if (localStorage.getItem("clientid") != null) {
                if(localStorage.getItem("payload") === null){
                    history.go(-1);
                }
        }

    }, []);
    useEffect(() => {
        if (window.site) {
            setConfigFlag(false)
            setConfig(window.site);
        }

    }, [window.site]);

    if (config.common && config.common.imagesCDN) {
        var img = config.common.imagesCDN;
    }

    const getTerms = async (e) => {
        setLoad(true);
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")

        await axios({
            method: 'GET',
            url: lambda + '/biddingTerms?appname=' + appname + "&clientid=" + clientId + "&token=" + token,
        })
            .then(function (response) {
                console.log(response)
                if (response.data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                }else{
                setInfo(response.data.result.data[0].content);
                setLoad(false)
                }

            });
    }
    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "clientid": localStorage.getItem("clientid"),
            "pagename": "BID TERMS",
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        let urlLink;
        if (localStorage.getItem("previousid")) {
            urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        } else {
            urlLink = lambda + '/activity?appname=' + appname
        }

        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }


    const handleBack = () => {
        // localStorage.removeItem("itemid");
        // localStorage.removeItem("payload");
        // history.go(-1);
        setTerms(false)
    }

    const handleAddBid = () => {
        setButtonDisabled(true);
        setBtnLoader(true);
       let payload = JSON.parse(localStorage.getItem("payload"))
        axios({
            method: 'POST',
            url: lambda + '/managebid?appname=' + appname + "&activity=add" + "&clientid=" + localStorage.getItem("clientid") + "&itemid=" + localStorage.getItem("itemid"),
            data: payload
        })
            .then(function (response) {
                console.log(response);
                if (response.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true);
                    setTerms(false);
                    setBtnLoader(false);
                }
                else if(response.data.result === "Added Successfully"){
                    history.push("/bidsuccess");
                    
                    localStorage.removeItem("payload");
                    setTerms(false);
                    setBtnLoader(false);
                }
            });
    }

    return (
        <>

            {showSessionPopupup && <SessionPopup />}
            <>


                {!ShowErrMsg &&
                    <>
                        {load ? <Loader /> :
                            <div className="asset-container bid_terms">
                                <><div className="asset-details-wrapper">
                                    <div className="hed">
                                        <div className="wrp">
                                            <h3>BID TERMS</h3>
                                            <button className="close-btn" onClick={handleBack}><span className="material-icons">close</span></button>

                                        </div>
                                    </div>
                                    <div className="wrp content">
                                        <h1>TERMS & CONDITIONS</h1>
                                        {ReactHtmlParser(info)}
                                        <div className="term_footer">
                                            <button className="back" disabled={agree} onClick={handleBack}>Back</button>
                                            <div className="form-check">
                                                <input className="check" type="checkbox" value="" id="flexCheckDefault" onClick={(e) => setAgree(e.target.checked)} />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    I agree to the terms  & Conditions
                                                </label>
                                            </div>
                                            {agree ? <button className="fill_btn yellow-gradient me-2" onClick={handleAddBid} disabled={isButtonDisabled}>{BtnLoader ? (<img src={img+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}ACCEPT & BID</button> : <button className="fill_btn yellow-gradient me-2" style={{ "opacity": 0.5 }}>ACCEPT & BID</button>}

                                        </div>
                                    </div>

                                </div><div className="blur-bg">

                                    </div></>
                                <Footer menus={menus} moreInfoCls={"fixed-footer"} />

                            </div>
                        }
                    </>

                }
            </>


        </>
    );
};

export default BidTerms;
