/***
**Module Name: Homepage
 **File Name :  Home.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 27 2022
 **Last modified on: Dec 27 2022
 **Description : contains Home page details.
 ***/
import React, { useEffect, useState,useContext } from "react";
import { Link } from "react-router-dom";

// import "../../src/assets/css/style.css"
import tmdbApi from "../api/tmdbApi";
import * as Config from "./../constants/Config";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import HeroSlide from "../components/Homepage/hero-slide/HeroSlide";
import HowDoes from "../components/Homepage/how-does-works/howDoes";
import JoinNow from "../components/Homepage/join-now/joinNow";
import HitLab from "../components/Homepage/hitlab/hitlab";
import AssetsGrid from "../components/Homepage/assetsgrid/assetsGrid";
import Loader from "../components/loader";
import axios from 'axios';
import { contentContext } from "../context/contentContext";


const Home = () => {
  const [assetGridData, setAssetGridData] = useState([]);
  // const [menus, setMenus] = useState([]);
  const [loader, setLoader] = useState(false);
  const [heroSlideData, setHeroSlideData] = useState([]); 
  const [showPopup, setShowPopup] = useState(false);
  const [config, setConfig] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [perpage, setPerpage] = useState(18);

  const { menus ,setInactiveFlag,userAgent} = useContext(contentContext);
  useEffect(() => {
    // setTimeout(function () { setLoader(false) }, 1000)
    if(localStorage.getItem("loc") === null){
      userAgent();
    }
    console.log('homeeee')
    setInactiveFlag(false)
    localStorage.removeItem("info")
    localStorage.removeItem("item")
    localStorage.removeItem("isAddOrDelete")
   heroSlideContent()
  //  AssetGridContent()
  }, []);

  useEffect(() => {
    if (window.site) {
      setConfig(window.site);

    }

  }, [window.site]);


  // const userAgent = async () => {
  // axios({
  //   method: 'GET',
  //   url: "https://d4nv8o5tzs3mt.cloudfront.net/",
  //   })
  //   .then(function (response) {
  //     let locData = JSON.stringify(response.data)
  //       localStorage.setItem("loc",locData);
  //   });
  // }


// console.log('config----->',config && config.client && config.client.siteUrl)

  // let date = new Date().toJSON();
  // console.log(date); 
  const heroSlideContent = async (name) => {
    try {
      setLoader(true)
        let payload = { 'featured': true ,'page':'landing'}

        const response = await tmdbApi.featuredContentHeroSlide(payload);
        if (response.statusCode === 200) {
            let data = response.result && response.result.data && response.result.data.length > 0 ? response.result.data : []
            if (data.length > 0) {
              const filterArray = data.filter((eachItem)=>{
                
                if(window && window.site && window.site.client && window.site.client.siteUrl == "https://orasimedia.com"){
                  if(eachItem.portraitimage != "")return eachItem
                }else if(window && window.site && window.site.client && window.site.client.siteUrl == "https://develop.orasimedia.com"){
                  if(eachItem.thumbnail != "")return eachItem
                }
                
                })
                const filterArray2 = data.filter(eachItem=>eachItem.portraitimage != "" && eachItem.portraitimage != undefined)
                // const filterArray3 = data.filter(eachItem=>((eachItem.portraitimage != "" && eachItem.portraitimage != undefined)|| (eachItem.landscapeimage != "" && eachItem.landscapeimage != undefined)) )
               
              setHeroSlideData(filterArray2)
              setAssetGridData(data)
           
              setLoader(false)
            }
            else{
              setLoader(false)
            }
           
        }
    } catch {
      setLoader(false)
        console.log("error");
    }
};

const AssetGridContent = async (name) => {
  try {
    setLoader(true)
   
      const response = await tmdbApi.featuredContentHeroSlide({'page':'landing'});
      if (response.statusCode === 200) {
          let data = response.result && response.result.data && response.result.data.length > 0 ? response.result.data : []
          if (data.length > 0) {
             setAssetGridData(data)
            setLoader(false)
          }
          else{
            setLoader(false)
          }  
         
      }
  } catch {
    setLoader(false)
      console.log("error");
  }
};
  return (
    <>
    {loader ? <Loader/> :null}

      <Header menus={menus} />
      <HeroSlide heroSlideData={heroSlideData} menus={menus} />
      <main id="main">
        <HowDoes menus={menus}/>
        <div className="section text-center">
          <JoinNow menus={menus}/>
        </div>
        {/* <HitLab /> */}
        <AssetsGrid assetGridData={assetGridData} />
      </main>
      <Footer menus={menus}/>

      {/* <div className="container">
        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Trending Movies</h2>
            <Link to={`/${Config.HOME_PAGE}/movie`}>
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.movie} type={movieType.popular} />
        </div>

        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Top Rated Movies</h2>
            <Link to={`/${Config.HOME_PAGE}/movie`}>
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.movie} type={movieType.top_rated} />
        </div>

        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Trending TV</h2>
            <Link to={`/${Config.HOME_PAGE}/tv`}>
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.tv} type={tvType.popular} />
        </div>

        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Top Rated TV</h2>
            <Link to={`/${Config.HOME_PAGE}/tv`}>
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.tv} type={tvType.top_rated} />
        </div>
      </div> */}
    </>
  );
};

export default Home;
