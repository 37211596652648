/***
**Module Name: SessionPopup
 **File Name :  SessionPopup.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  Kiran
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Mar 29 2023
 **Last modified on:  Mar 29 2023
 **Description : contains category page details.
 ***/
 import React, { useState, useEffect, useRef, useContext} from "react";
 import tmdbApi from "../api/tmdbApi";

 import { useHistory } from "react-router";


 import * as Config from "./../constants/Config";
 import Header from "../components/header/Header";
 import Footer from "../components/footer/Footer";
 import { useParams } from 'react-router-dom';
 import Modal from 'react-bootstrap/Modal';
 import axios from "axios";
 import SweetAlert from 'react-bootstrap-sweetalert';
 import { contentContext } from "../context/contentContext";

const InactivePopup =(props)=> {
    const history = useHistory();
 
    
    const {clientData,wishListData, setWishListData,formValues, setFormValues ,setClientData1, GetClientDataFunction,wishlistCount, setwishlistCount,  showCategoryData, setShowCategoryData, setInitialCategoriesData1, hideMenu, setHideMenu, isShowMyContent, setIsShowMyContent, pageNumber, setPageNumber, assetTotal, setAssetTotal, perpage, setPerpage, totalPagesArray, setTotalPagesArray, totalPages, setTotalPages, setGrid, setList, setMenuCategoryName,
        selectedOptions,setParamType,setActivePayload,setSearchPayload,SetSearchTitleInput, setFormatsClick,setSelectedOptions,multiSelectFields,SetEnqFormValues, setMultiSelectFields
       } = useContext(contentContext)

 
const onConfirm =  () => {
    localStorage.removeItem("token");
    localStorage.removeItem("clientid");
    localStorage.removeItem("login");
    localStorage.removeItem("currentSessionClientTime")
    unSetValues()
    
    history.push("/");
  
}
const unSetValues = () => {
    localStorage.removeItem("wishlistcount");
    localStorage.removeItem("previousid");
    localStorage.removeItem("formatsClick");
    setFormatsClick(false)
    setHideMenu(false)
    setInitialCategoriesData1([])
    setShowCategoryData(true)
    setMenuCategoryName("")
    setClientData1([])
    setFormValues([])
    setPageNumber(1)
    setAssetTotal(0)
    setPerpage(18)
    setTotalPagesArray(0)
    setGrid(true);
    setList(false);
    setTotalPages(0)
    setSelectedOptions([])
    SetEnqFormValues([])
    setMultiSelectFields([])
    setParamType("")
    setAssetTotal(0)
    SetSearchTitleInput("")
    setActivePayload({})
    setSearchPayload({})
    setIsShowMyContent(false)
    // setShowSessionPopupup(false)

  }


    return(
        <>
            <Modal className="access-denied" show={true} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => onConfirm()}><span className="material-icons">close</span></button>
                        <span className="material-icons access-denied-icon">https</span>
                        <h3>Access Denied</h3>
                        <p>Your account has been deactivated.</p>
                        <p>Please contact administrator</p>
                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>close</button>
                    </div>
                </div>

            </Modal>
                        
        </>
    )
}
export default InactivePopup