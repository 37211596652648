/***
**Module Name: Homepage assets  
 **File Name :  assetsGrid.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 27 2022
 **Last modified on: Dec 27 2022
 **Description : contains homepage assets component details.
 ***/
import React, { useEffect, useRef, useState, useContext } from "react";
import tmdbApi from "../../../api/tmdbApi";
import $ from 'jquery';
import { useHistory } from "react-router";
import { contentContext } from "../../../context/contentContext";
import moment from "moment";

import * as Config from "../../../constants/Config";






const AssetsGrid = (props) => {

    const history = useHistory();
    const [config, setConfig] = useState({});
    const [intialcontentData, setIntialcontentData] = useState( []);
    const [isLoad, setIsLoad] = useState(false);
    const [catName, setcatName] = useState([]);
    const [myArray, setMyArray] = useState([]);
    const [activeCountArr, setActiveCountArr] = useState([]);
    const [loader, setLoader] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [ActiveTotalPages, setActiveTotalPages] = useState({});
    const [filterDataClick, seFilterDataClick] = useState(false);

    const [activeLabelName, setActiveLabelName] = useState("ALL");

// console.log('assetGridData propsss',props.assetGridData)
    let img = window.app.img;
    const { Categories, categoryName1,formatsClick, setFormatsClick, setCategoryName1, isLoading, setIsLoading } = useContext(contentContext)

    // const handleCategory = async (name) => {
    //     try {
    //         setIsLoading(true)
    //         let payload = name == 'ALL' ? { 'featured': true, 'assetcount': 12 } : { 'category': [name], 'featured': true, 'assetcount': 12 }
    //         //   console.log('payload',payload)
    //         const response = await tmdbApi.featuredContent(payload);
    //         if (response.statusCode === 200) {
    //             let data = response.result && response.result.data && response.result.data.length > 0 ? response.result.data : []
    //             setIntialcontentData(data)
    //             if (data.length > 0) {
    //                 setIsLoading(false)
    //             }
    //         }
    //     } catch {
    //         console.log("error");
    //     }
    // };
    const menusData = categoryName1.filter((eachitem) => eachitem.label !== "FORMATS")

    // const menusData = categoryName1
    // const newArray = menusData.map(item => ({
    //     [item.label+' Count']: 1,
    //     name: item.label
    // }));
    // console.log('newArra',newArray)
    useEffect(() => {
        if (window.site) {
            setConfig(window.site);
            Categories()
          
        }

    }, [window.site]);
useEffect(()=>{
    AssetGridContent(1)
},[])

    useEffect(()=>{
        if(menusData.length>0){
            const initialCount = {};
            menusData.forEach(item => {
              initialCount[item.label] = 0;
            });
            setActiveCountArr({...initialCount,'ALL':1});
        }
     
    },[menusData.length>1])
   
    if (config.common && config.common.imagesCDN) {
        img = config.common.imagesCDN;
    }

    const validateData = (k) => {
        let flag = true;
        console.log('ActiveTotalPages[activeLabelName]',ActiveTotalPages[activeLabelName])
        console.log('activeCountArr[activeLabelName]',k[activeLabelName])
        console.log('activeLabelName',activeLabelName)
        if(ActiveTotalPages[activeLabelName] !== undefined && (ActiveTotalPages[activeLabelName] >= k[activeLabelName])){
                 flag = false
                 console.log('validate data')
                 setActiveCountArr({...activeCountArr,[activeLabelName]:1})
        }else{
            flag = true 
            
        }

        return flag
    }

    function handleJoin() {
        history.push('./signup');
    }
    const handleMoreInfo = (e, id) => {
        history.push("/moreinfo/" + id);
    }

    const AssetGridContent = async (value) => {
        console.log('AssetGridContent active count arr',activeCountArr)
        console.log('AssetGridContent activeLabelName',activeLabelName)
        console.log('AssetGridContent valueee',value)

        console.log('AssetGridContentactive count',activeCountArr[activeLabelName])

        try {
            setLoader(true)
            let payload =

                // activeLabelName == "ALL" ? { pageNumber: activeCountArr[activeLabelName], perpage: 12 } : { pageNumber: activeCountArr[activeLabelName], perpage: 12, contentSearch: { category: [activeLabelName] } }
                activeLabelName == "ALL" ? { pageNumber: value, perpage: 12 } : { pageNumber: value, perpage: 12, contentSearch: { category: [activeLabelName] } }
            const response = await tmdbApi.featuredContent(payload);
            seFilterDataClick(false)
            if (response.statusCode === 200) {
                let data = response.result && response.result.data && response.result.data.length > 0 ? response.result.data : []
                if (data.length > 0) {
                    setIntialcontentData(data)
                    //    setAssetGridData(data)
                    // console.log('data--->',data)
                    let totalCount = response?.result?.totalContent
                    let totalPages = totalCount /12
                    totalPages = Math.ceil(totalPages)
                    // console.log('data--->',totalPages)

                    setActiveTotalPages(prevCountObject => ({
                        ...prevCountObject,
                        [activeLabelName]: totalPages
                    }));
                    
                    // setActiveTotalPages({...ActiveTotalPages, [activeLabelName]: totalPages})
                    setLoader(false)
                }
                else {
                    setLoader(false)
                }

            }
        } catch {
            setLoader(false)
            console.log("error");
        }
    };

    // console.log('countarr',activeCountArr)
    // console.log('ActiveTotalPages',ActiveTotalPages)
    // useEffect(()=>{
    //     console.log('use effect total pages',ActiveTotalPages)
    // },[ActiveTotalPages])
useEffect(() => {
    const timerId = setInterval(() => {
        
        console.log('active count arr  11',activeCountArr)
        // console.log('active count   111',activeCountArr[activeLabelName])
       
        if(ActiveTotalPages[activeLabelName] !== undefined && (ActiveTotalPages[activeLabelName] <= activeCountArr[activeLabelName])){
           
            setActiveCountArr({...activeCountArr,[activeLabelName]:1})
            AssetGridContent(1);
          }
          else{
            setActiveCountArr(prevCountObject => ({
                ...prevCountObject,
                [activeLabelName]: (prevCountObject[activeLabelName] || 0) + 1
            }));
            AssetGridContent((activeCountArr[activeLabelName] || 0) + 1);
          }
               console.log('ActiveTotalPages------>',ActiveTotalPages)

    }, 60000);
    return () => clearInterval(timerId);
}, [activeCountArr,activeLabelName]);

// useEffect(()=>{
       
  
//     const id = setInterval(() => {
//       console.log('timeeeeeeeeeeeeeeeeeeeeeeeeeee inc',activeLabelName)
//       setActiveCountArr(prevCountObject => ({
//           ...prevCountObject,
//           [activeLabelName]: (prevCountObject[activeLabelName] || 0) + 1
//         }));
    
//         setIntervalId(id);
//         return () => clearInterval(intervalId);
//     }, 10000);
 
// },[])

    // const filterData = (e,activeCategory) => {
    //     setActiveCountArr(prevCountObject => ({
    //         ...prevCountObject,
    //         [activeCategory]: (prevCountObject[activeCategory] || 0) + 1
    //       }));
    //   setActiveLabelName(activeCategory)
    //   AssetGridContent2(activeCategory);
    // }

    // useEffect(()=>{
    // console.log('useEffect in filterdata')
    //     if(filterDataClick){
    //         AssetGridContent();
    //     }
    // },[filterDataClick === true,activeCountArr,activeLabelName])
    const filterData = async (e, activeCategory) => {
        seFilterDataClick(true)
        let k = {...activeCountArr,[activeCategory]: (activeCountArr[activeCategory] || 0) + 1}
        // let updatedCountObject = {
        //     ...activeCountArr,
        //     [activeCategory]: (activeCountArr[activeCategory] || 0) + 1,
        // };
   
        // const isValid = validateData(k)
        if(ActiveTotalPages[activeCategory] !== undefined && (ActiveTotalPages[activeCategory] <= k[activeCategory])){
           
           k ={...activeCountArr,[activeCategory]:1}
         }
        // if (isValid) {
            // setActiveCountArr((prevCountObject) => {
            //     const updatedCountObject = {
            //         ...prevCountObject,
            //         [activeCategory]: (prevCountObject[activeCategory] || 0) + 1,
            //     };
            //     // setActiveLabelName(activeCategory);

            //     AssetGridContent3(updatedCountObject, activeCategory);
            //     return updatedCountObject;
            // });
            setActiveLabelName(activeCategory);
            AssetGridContent3(k, activeCategory);
        // }
    };
    const onClickFormats = () =>{
        const token = localStorage.getItem("token")
        const clientId = localStorage.getItem("clientid")
        // localStorage.setItem("formatsClick","formatsClick")
        setFormatsClick(true)
        if (clientId != null && token != null) {

            history.push("/content")

        } else {
            history.push('/login')
        }

    }
  
    const AssetGridContent3 = async (updatedCountObject, activeCategory) => {
        try {
            setLoader(true);
    
            let payload =
                activeCategory === "ALL" ?
                { pageNumber: updatedCountObject[activeCategory], perpage: 12 } :
                { pageNumber: updatedCountObject[activeCategory], perpage: 12, contentSearch: { category: [activeCategory] } };
    
            const response = await tmdbApi.featuredContent(payload);
    
            if (response?.statusCode === 200) {
                let data = response?.result && response?.result?.data && response?.result?.data?.length > 0 ? response?.result?.data : [];
              
                
                // setActiveTotalPages(totalPages)
                if (data.length > 0) {
                    setIntialcontentData(data);
                    let totalCount = response?.result?.totalContent
                    let totalPages = totalCount /12
                    totalPages = Math.ceil(totalPages)
                    // console.log('data--->',totalPages)
                    setActiveTotalPages(prevCountObject => ({
                        ...prevCountObject,
                        [activeCategory]: totalPages
                    }));
                 
                    // setActiveTotalPages({...ActiveTotalPages, [activeLabelName]: totalPages})
                    setLoader(false);
                    setActiveCountArr(prevCountObject => ({
                        ...prevCountObject,
                        [activeCategory]: (prevCountObject[activeCategory] || 0) + 1
                      }));
                    //   setActiveLabelName(activeCategory);
                } else {
                    setLoader(false);
                }
            }
    

        } catch (error) {
            setLoader(false);
            console.error("error", error);
        }
    };
    
    const handleMenu = (e, name) => {
        setActiveLabelName(name)
     
    }
    // console.log('menusData',menusData)

    const cardWidth = $(".portfolio-item").width() != undefined ? $(".portfolio-item").width() : 169;
    return (
        <div className="portfolio_block">
            <div className="section">
                <section id="portfolio" className="portfolio">
                    <div className="container">
                        <h1 className="sec-heading">CONTENT FROM<br /><span>ALL ACROSS THE GLOBE</span></h1>

                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <ul id="portfolio-flters">
                                    <li data-filter="*" className={`${activeLabelName == "ALL" ? 'filter-active' : ''}`} onClick={(e) => filterData(e, 'ALL')}>All</li>
                                    {menusData && menusData?.length > 0 && menusData?.map((item, i) => {

                                        let labelName = item?.label
                                        return (
                                            <li key={i} data-filter="*" className={`${labelName == activeLabelName ? 'filter-active' : ''}`} onClick={(e) => filterData(e, labelName)}>{labelName}</li>                                          
                                        )
                                    })}
                                   <li data-filter="*" className={`${activeLabelName == "FORMATS" ? 'filter-active' : ''}`} onClick={(e) => onClickFormats(e, 'FORMATS')}>FORMATS</li>

                                                                        
                                </ul>
                            </div>
                        </div>

                        <div className="row portfolio-container1">
                       
                            {/* ----------------------------------------------------------------------------------- */}
                            
                            {loader ?  <div className="orasi-preloader">
                                        <img src={img+"orasi/common/images/preloader.png"} />
                                    </div> : null} 
                          {  intialcontentData && intialcontentData?.length > 0 ? intialcontentData?.map((item, i) => {

                                let picName = item?.mediaType === 'video' ? 'videoclip-defaulr' : item?.mediaType === 'audio' ? 'musicfile-default' : item?.mediaType === 'pdf' ? 'pdf-default' : item?.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'
                               
                                let defaultImg = `${img}orasi/common/images/img-default.jpg`;
                               
                                let imgUrl = 
                                item?.portraitimage !== "" && item?.portraitimage !== undefined 
                                ? (item?.portraitimage?.startsWith("http") 
                                    ? item?.portraitimage
                                    : img + item?.portraitimage ) 
                                : item?.landscapeimage !== "" && item?.landscapeimage !== undefined 
                                ? (item?.landscapeimage?.startsWith("http")  
                                    ?item?.landscapeimage
                                    :img + item?.landscapeimage) 
                                : defaultImg;
                            
                                
                                let categoryItem = item?.category && typeof(item.category) != 'string' ? item?.category && item?.category?.length > 1 ? item?.category?.join(', ') : item?.category : ""
                                            
                                return (

                                    <div className="col6-sm1 col-md-6 portfolio-item" key={i} >
                                        <div className="portfolio-wrap" style={{cursor:"default"}} onClick={e => handleMoreInfo(e, item?.contentid)}>
                                          
                                            <img src={imgUrl + "?auto=compress,format&width=" + cardWidth+"&q=95"} className="asset-portrait-thumb" alt="" />
                                            
                                            <div className="portfolio-info black-gradient" >
                                                <div className="thumb-info-block black-gradient" >
                                                    <div className="thumb-info">
                                                     
                                                        <h6 className="thumb-title">{item?.title}</h6>
                                                        {item?.category ? <h6 className="sub-title">{categoryItem}</h6>:null}
                                                        <ul className="thumb-meta">
                                                           
                                                            {item?.duration ? <li>{item?.duration}</li> : null}
                                                            {item?.releasedate ? <li> { item?.releasedate && moment(item?.releasedate).format('DD-MMM-YY')}</li> : 
                                                            item?.releaseyear ? <li>  {item?.releaseyear} </li> : null}

                                                            {item?.keywords ? <li>{item?.keywords}</li> : null}

                                                        </ul>
                                                        <p className="thumb-description">{item?.synopsis}</p>
                                                        <button className="border-btn_sm" style={{cursor:"pointer"}} onClick={e => handleMoreInfo(e, item?.contentid)}>MORE INFO</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                                :
                             
                                (

                                   
                                    <div className="col-md-12 comingsoon-wrapper">
                                        <div className="comingsoon-block">
                                            <img src={img+"orasi/common/images/comingsoon.png"} />
                                            <h6>Stay Tuned!</h6>
                                            <p>The Orasi Media team is updating the latest content into this category</p>
                                        </div>
                                    </div>


                                )

                            }


                        </div>
                        {intialcontentData && intialcontentData?.length > 0  &&
                        <div className="text-center">
                            <button className="fill_btn yellow-gradient" onClick={handleJoin}>VIEW ALL</button>
                        </div>
}
                    </div>
                </section>
            </div>
        </div>
    );
};



export default AssetsGrid;
