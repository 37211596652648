/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
 import React, { useState, useEffect, useContext } from "react";
 import { useHistory } from "react-router";
 import Footer from "../../components/footer/Footer";
 import { useParams } from 'react-router-dom';

 import 'react-toastify/dist/ReactToastify.css';
 import { contentContext } from "../../context/contentContext";

 import Loader from "../../components/loader";
 
 
 let { lambda, appname } = window.app;
 
 
 const BidSuccess = () => {
   let { id } = useParams();
   const [config, setConfig] = useState({});
   const history = useHistory();
   const [showSessionPopupup, setShowSessionPopupup] = useState(false);

   const [configFlag, setConfigFlag] = useState(true);
 
 
 
   const {  menus,setActiveTab } = useContext(contentContext);
 

 
   useEffect(() => {

 
   }, []);
   useEffect(() => {
     if (window.site) {
       setConfigFlag(false)
       setConfig(window.site);
     }
 
   }, [window.site]);

   const handleBack = () => {
    setActiveTab("bids")
    history.push("/auction");
    localStorage.removeItem("itemid");
}
const handlePlaceBid = () => {
  history.push("/bids/" + localStorage.getItem("itemid"));
  localStorage.removeItem("itemid");
}
const handleBackHome = () => {
  history.push("/content");
  localStorage.removeItem("itemid");
}

   return (
     <>

       {/* {showSessionPopupup && <SessionPopup />} */}
       <>
 
 
     <>
             {configFlag ? <Loader /> :
               <div className="asset-container bid_popup">
               
                   <><div className="asset-details-wrapper">
                     <div className="bid_success">
                       <div className="icon">
                         <span className="material-icons"> done_outline </span>
                       </div>
                       <h3>BID Successfully Placed</h3>
                       <button class="fill_btn yellow-gradient me-2" onClick={handleBackHome}>Home</button>
                       <button class="fill_btn yellow-gradient me-2" onClick={handleBack}>Back to My BIDS</button>
                       <button class="fill_btn yellow-gradient me-2" onClick={handlePlaceBid}>Place Another BID</button>
                     </div>
 
                   </div><div className="blur-bg">
                       {/* <div className="black-overlay"></div> */}
                       {/* <img src={imgUrl.toString()} alt="thumb" /> */}
                     </div></> 
 
                 <Footer menus={menus} moreInfoCls={"fixed-footer"} />
                 
               </div>
             }
           </>
 
         
       </>
 

 
      
 
     </>
   );
 };
 
 export default BidSuccess;
 