/***
**Module Name: Partners
 **File Name :  partners.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 27 2022
 **Last modified on: Dec 27 2022
 **Description : contains team page details.
 ***/
 import React, { useEffect, useState, useContext } from "react";

 // import "../../src/assets/css/style.css"
 import tmdbApi from "../api/tmdbApi";
 import Header from "../components/header/Header";
 import Footer from "../components/footer/Footer";
 import ReactHtmlParser from 'react-html-parser';
 import SessionPopup from "./SessionPopup";
 import $ from "jquery";
 import axios from "axios";
 
 import * as Config from "../constants/Config";
 import { contentContext } from "../context/contentContext";
 import Loader from "../components/loader"
 import Modal from 'react-bootstrap/Modal';
 import moment from "moment";
 
 import InsideHeader from "../components/InnerHeader/Header";
 let { lambda, appname } = window.app;
 
 const News = () => {
 
   const [config, setConfig] = useState({});
   const [teamBoard, setTeamBoard] = useState({});
   const [teamMember, setTeamMember] = useState({});
   const [newsData, setNewsData] = useState([]);
   const [singleNewsData, setSingltNewsData] = useState([]);
   const [about, setAbout] = useState('');
   const [showSessionPopupup, setShowSessionPopupup] = useState(false);
 
   const [show, setShow] = useState(false)
   const { menus, setContentSearchFlag, isLoading, setIsLoading, sorting,newsPerPageConst, setNewsPerPageConst, setSorting, newspageNumber, setNewsPageNumber, newsperpage, setNewsPerpage, newsAssetTotal, setNewsAssetTotal, newsTotalPages, setNewsTotalPages, newstotalPagesArray, setNewsTotalPagesArray, contentSearchFlag, setRoute } = useContext(contentContext);
   const [showDescription, setShowDescription] = useState(false);
   const [btnLoader, setBtnLoader] = useState(false);
   const [partnersData, setPartnersData] = useState([]);
 
   useEffect(() => {
     if (window.site) {
       setConfig(window.site);
 
     }
     setContentSearchFlag(false)
     setRoute("notcontent")
   }, [window.site]);
 
   if (config.common && config.common.imagesCDN) {
     var img = config.common.imagesCDN;
   }
 
   useEffect(() => {
     {
       menus && menus.length > 0 && menus.map(function (item, i) {
         if (item.menuid === 100003) {
           setTeamBoard(item.team[0].members);
           setTeamMember(item.team[1].members)
         }
       })
     }
 
   }, [menus]);
 
 
   useEffect(() => {
 
     news()
     clientActivity()
   }, [newsperpage, newspageNumber, sorting])
   const clientActivity = () => {
     let path = window.location.pathname.split("/");
     // const pageName = path[path.length - 1];
     var presentTime = moment();
     let payload;
 
     payload = {
       "clientid": localStorage.getItem("clientid"),
       "pagename": 'PARTNERS',
       "pageurl": window.location.href,
       "starttime": presentTime,
       "useragent": JSON.parse(localStorage.getItem("loc"))
 
     };
 
 
     let urlLink;
     if (localStorage.getItem("previousid")) {
       urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
     } else {
       urlLink = lambda + '/activity?appname=' + appname
     }
 
     axios({
       method: 'POST',
       url: urlLink,
       data: payload
     })
       .then(function (response) {
         if (response.data.statusCode === 200) {
           localStorage.setItem("previousid", response.data.result)
         }
       });
   }
 
   const handleSortBy = (e) => {
     const selectedValue = e.target.value; // Get the selected value
     setSorting(selectedValue);
 
     // Send the sorting value (e.g., to an API or another function)
 
   };
   const defaultImageUrl = img+"orasi/client/resources/orasiv1/images/newsDefaultwhite.png";
 
 
   const handleClose = () => {
     setShow(false)
   }
   const handleClick = async (Id) => {
     setShow(true)
     const token = localStorage.getItem("token");
     const userid = localStorage.getItem("userId");
 
     try {
       setIsLoading(true)
       // setIsLoading2(true); // Start loading
 
       // Make API call
       const response = await axios.post(`${lambda}/news?appname=${appname}&userid=${userid}&newsid=${Id}${token ? `&token=${token}` : ''}`);
 
       console.log("S3 Response: ", response);
 
 
       // Set content data if response is valid
       // const newsData = response?.data?.result?.data?.[0];
       // setEditContent(newsData);
       // setValue(newsData?.description);
       // setDefaultImage(newsData?.defaultimage);
       console.log("responseeeeeeeeeeeeeee", response)
       setSingltNewsData(response?.data?.result?.data?.[0])
       setIsLoading(false)
       // setIsLoading2(false); // Stop loading after API call
     } catch (error) {
       console.error("Error during API call:", error);
       // setIsLoading2(false); // Stop loading in case of error
     }
   }
   const calculateTimeAgo = (date) => {
     const now = new Date();
     const past = new Date(date);
 
     // Calculate the difference in time in milliseconds
     const timeDifference = now - past;
 
     // Calculate the difference in days
     const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
 
     // Define constants for months and years
     const daysInMonth = 30;
     const daysInYear = 365;
 
     if (daysDifference >= daysInYear) {
       const yearsAgo = Math.floor(daysDifference / daysInYear);
       return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
     } else if (daysDifference >= daysInMonth) {
       const monthsAgo = Math.floor(daysDifference / daysInMonth);
       return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
     } else if (daysDifference >= 1) {
       return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
     } else {
       const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
       return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
     }
   };
 
   const previousClick = (e) => {
  
    const k=parseInt(newspageNumber)
     setNewsPageNumber( k- 1)
   }
   const handleNext = (e) => {
    const k=parseInt(newspageNumber)
     setNewsPageNumber(k + 1)
   }
  
   const handlePagechange = (e) => {
     let name = e.target.name;
     let value = e.target.value;
     if (name === "pageNo") {
       setNewsPageNumber(value)
       
     }
 
 
     if (name === "perPage") {
      
       setNewsPerpage(parseInt(value))
       setNewsPageNumber(1)
     }
   }
 
   
   const news = async () => {
     const token = localStorage.getItem("token");
     const userid = localStorage.getItem("userId");
     const sortBy = sorting;
     const pageNumber = newspageNumber;
     const assetcount = newsperpage;
 
     try {
       setIsLoading(true);
       const response = await axios({
         method: 'post',
         url: `${lambda}/news`,
         params: { appname, token, userid, sortBy, pageNumber, assetcount }, // Use params for query parameters in GET requests
         headers: {
           'Content-Type': 'application/json'
         }
       });
 
       if (response?.data?.result === "Invalid token or Expired") {
         setShowSessionPopupup(true)
       } else {
         // console.log(response?.data?.result)
         setIsLoading(false);
         if(response?.data?.result?.data?.length>0){
  let assetTotal = response?.data?.result?.totalcount
           || 0
         setNewsData(response?.data?.result?.data)
         setNewsAssetTotal(assetTotal)
 
         let totalPages = assetTotal / newsperpage;
 
         totalPages = Math.ceil(totalPages)
         console.log("totalPages--------------->", totalPages)
         let totalArray = [];
         for (var i = 0; i < totalPages; i++) {
           totalArray.push(i + 1)
         }
         console.log("totalllllllllllllllllllllllll", totalArray)
         setNewsTotalPagesArray(totalArray)
         setNewsTotalPages(totalPages)
         }
        
         // setIsLoading(false)
         // setNoDataMsg(false)
 
 
       }
 
       // setData(response?.data?.result?.data)
 
     } catch (error) {
       console.error('Error fetching news:', error);
       // setIsLoading(false); // Ensure this is called in case of an error
     } finally {
       // setIsLoading(false); // Ensure this is called to stop loading spinner regardless of success or failure
     }
   };
   const handleDescription = (e, item) => {
     setAbout(item)
     setShowDescription(!showDescription)
   }
 
   //  const img= "https://orasi.imgix.net/orasi/client/resources/orasiv1/images";
   // const cardWidth = $(".team-card-img-block").width();
   const cardWidth1 = $(".slider-section").width();
   const cardWidth = $(".main_thumb").width();
   return (
 
 
     <>
       <Modal show={showDescription} onHide={handleDescription} className="modal title-des-popup" id="descriptionPopup" >
         <Modal.Header closeButton>
           <Modal.Title>About</Modal.Title>
         </Modal.Header>
         <Modal.Body>
 
           <p style={{ whiteSpace: "pre-line" }}>{about}</p>
 
         </Modal.Body>
 
 
       </Modal>
       {showSessionPopupup && <SessionPopup />}
       {/* <Header menus={menus} activeClass={"100009"} /> */}
       {localStorage.getItem("token") ? <InsideHeader activeClass={"6"} /> : <Header menus={menus} activeMenu={"news"} activeClass={"100010"} />}
       <main id="main">
         {isLoading ? (
           <div className="orasi-preloader">
             <img src={"https://d2aif2zmoi437u.cloudfront.net/orasi/common/images/preloader.png"} alt="Preloader" />
           </div>
         ) : newsData?.length > 0 ? (
           <div className="section inner-container news_blogs">
             <div className="container aos-init aos-animate" data-aos="fade-up">
               <div className="d-flex align-items-center justify-content-center mt-3">
               <h1 className="sec-heading "><span>NEWS</span>
               <select
                   className="form-control select form-select"
                   name="sortBy"
                   placeholder="sortBy"
                   onChange={(e) => handleSortBy(e)}
                   value={sorting}
                 >
                   <option value="ascending">Newest</option>
                   <option value="descending">Oldest</option>
                 </select>
               </h1>
                
               </div>
               <div className="row">
                 {newsData?.map((item) => (
                   <div
                     key={item?.newsid}
                     className="col-md-3 block"
 
                   >
                     <div className="main_thumb" onClick={() => handleClick(item?.newsid)} style={{ cursor: 'pointer' }}>
                       <div className="img-dummy"></div>
                       <img
                         src={
                           typeof item?.defaultimage === 'string' && item.defaultimage.trim() !== ""
                             ? `${window?.site?.common?.imagesCDN}${item.defaultimage}?auto=compress,format&width=${cardWidth}`
                             : `${defaultImageUrl}?auto=compress,format&width=${cardWidth}`
                         }
                         className="asset-portrait-thumb"
                         alt="default"
                       />
 
                     </div>
                     <div className="thumb-content">
                       <div className="newsthumb-heading"onClick={() => handleClick(item?.newsid)} style={{ cursor: 'pointer' }}>
                         <h1 className="thumb-heading">{item?.title}</h1>
                       </div>
                       <div className="newsthumb-body">
                         <p className="news-description">
                           {ReactHtmlParser(item?.description?.slice(0, 370))}
                         </p>
                       </div>
                       <div className="newsthumb-footer">
                         <p className="mt-2 fst-italic">{calculateTimeAgo(item?.created)}</p>
                       </div>
                     </div>
                   </div>
                 ))}
               </div>
               {newsAssetTotal > newsPerPageConst ? (
   <div className="row pagenation pgn1">
     <div className="pagenation_prv">
       <button
      
         disabled={newspageNumber == 1}
         className="jr-btn btn-white btn btn-default btn-block"
         onClick={(e) => previousClick(e)}
       >
         Previous
       </button>
     </div>
     <div className="pagenation_page">
       <label>Page :</label>
       <select
         className="form-control custom-select u-w60 custom-select-sm form-control form-control-sm form-select"
         name="pageNo"
         value={newspageNumber}
         onChange={(e) => handlePagechange(e)}
       >
         { newstotalPagesArray&&newstotalPagesArray?.map((item, i) => (
           <option key={i} value={item}>
             {item}
           </option>
         ))}
       </select>
       <label>
         <strong> of {newsTotalPages}</strong>
       </label>
     </div>
     <div className="pagenation_count">
       <label>
         {newspageNumber * newsperpage >= newsAssetTotal
           ? newsAssetTotal
           : newspageNumber * newsperpage}{" "}
         of {newsAssetTotal}
       </label>
     </div>
     <div className="pagenation_perpage">
       <label>Per Page:</label>
       <select
         className="form-control custom-select u-w60 custom-select-sm form-control form-control-sm form-select"
         name="perPage"
         onChange={(e) => handlePagechange(e)}
         value={newsperpage}
       >
         <option value={12}>12</option>
         <option value={24}>24</option>
         <option value={36}>36</option>
         <option value={48}>48</option>
         <option value={60}>60</option>
         <option value={72}>72</option>
         <option value={84}>84</option>
         <option value={96}>96</option>
       </select>
     </div>
     <div className="pagenation_next">
       <button
         disabled={newspageNumber == newsTotalPages}
         className="jr-btn btn-white btn btn-default btn-block"
         onClick={(e) => handleNext(e)}
       >
         Next
       </button>
     </div>
   </div>
 ) : null}
 
 
             </div>
             <Modal className="news-modal-popup" show={show}>
               <div className="modal-body">
                 <div className="header-title">
                   <h5 className="modal-title popup-title">{singleNewsData?.title}</h5>
                   <button
                     type="button"
                     className="pop_close"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                   >
                     <span className="material-symbols-outlined" onClick={handleClose}>
                       close
                     </span>
                   </button>
                 </div>
                 <div className="d-flex align-items-center justify-content-between mt-2 mb-2 created">
                   <p>Created by {singleNewsData?.createdBy?.username}</p>
                   <p>{calculateTimeAgo(singleNewsData?.created)}</p>
                 </div>
                 <div className="row rev">
                   <div className="col-md-6">
                     <p className="text-overflow">
                       {ReactHtmlParser(singleNewsData?.description)}
                     </p>
                     <div className="d-flex align-items-center social_media">
                       {singleNewsData?.socialmedia?.linkedin?.length > 0 && (
                         <a
                           href={singleNewsData?.socialmedia?.linkedin}
                           target="_blank"
                           rel="noopener noreferrer"
                         >
                           <img
                             src={img+"orasi/admin/resources/orasiv1/images/linkedin.png"}
                             alt="LinkedIn"
                           />
                         </a>
                       )}
                       {singleNewsData?.socialmedia?.twitter?.length > 0 && (
                         <a
                           href={singleNewsData?.socialmedia?.twitter}
                           target="_blank"
                           rel="noopener noreferrer"
                         >
                           <img
                             src={img+"orasi/admin/resources/orasiv1/images/twitter.png"}
                             alt="Twitter"
                           />
                         </a>
                       )}
                       {singleNewsData?.socialmedia?.facebook?.length > 0 && (
                         <a
                           href={singleNewsData?.socialmedia?.facebook}
                           target="_blank"
                           rel="noopener noreferrer"
                         >
                           <img
                             src={img+"orasi/admin/resources/orasiv1/images/facebook.png"}
                             alt="Facebook"
                           />
                         </a>
                       )}
                       {singleNewsData?.socialmedia?.youtube?.length > 0 && (
                         <a
                           href={singleNewsData?.socialmedia?.youtube}
                           target="_blank"
                           rel="noopener noreferrer"
                         >
                           <img
                             src={img+"orasi/admin/resources/orasiv1/images/youtube-logo.png"}
                             alt="YouTube"
                           />
                         </a>
                       )}
                     </div>
                   </div>
                   <div className="col-md-6">
                     <div className="slider-section">
                       <ul>
                         {singleNewsData?.images?.length > 0 ? (
                           singleNewsData.images.map((image, index) => (
                             <li key={index}>
                               <img
                                 src={`${window?.site?.common?.imagesCDN}${image.filepath}?auto=compress,format&width=${cardWidth1}`}
                                 alt="News Image"
                               />
                             </li>
                           ))
                         ) : (
                           <li>
                             <img
       src={`${defaultImageUrl}?auto=compress,format&width=${cardWidth1}`}
       alt="defaultimage"
     />
                           </li>
                         )}
                       </ul>
                     </div>
                   </div>
                 </div>
               </div>
             </Modal>
           </div>
         ) : (
           <div className="section inner-container nodata-found">
             <div className="searchfail-block">
               <img src={"https://d2aif2zmoi437u.cloudfront.net/orasi/common/images/search-fail.png"}className="searchfail-graphic mt-5" alt="No News" />
               <h6>No News Updates Yet</h6>
               <p>Please stay tuned while Orasi team is working on it</p>
             </div>
           </div>
 
         )}
       </main>
 
 
       <Footer menus={menus} />
     </>
   );
 };
 
 export default News;