/***
**Module Name: term and condtions
 **File Name :  Terms.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains Terms and condition details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import tmdbApi from "../api/tmdbApi";

//  import "../../src/assets/css/style.css"

import * as Config from "./../constants/Config";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from "react-router-dom";
import { contentContext } from "../context/contentContext";
import InsideHeader from "../components/InnerHeader/Header";
import axios from "axios";
import moment from "moment";
let { lambda, appname } = window.app;

const Terms = () => {
    const history = useHistory();

    const [config, setConfig] = useState({});
    // useEffect(() => {
    //   appMenus();
    // }, []);

    useEffect(() => {
        if (localStorage.getItem("token")){
            clientActivity()
          }

        if (window.site) {
            setConfig(window.site);

        }

    }, [window.site]);
    console.log('localStorage.getItem("token")', localStorage.getItem("token"))

    if (config.common && config.common.imagesCDN) {
        var img = config.common.imagesCDN;
    }
    // const appMenus = async () => {
    //   try {
    //     console.log(tmdbApi);
    //     const response = await tmdbApi.appMenus();
    //     setMenus(response.result)
    //     console.log(response);
    //   } catch {
    //     console.log("error");
    //   }
    // };
    const clientActivity = () => {
        let path = window.location.pathname.split("/");
        // const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;
        
            payload = {
                "clientid": localStorage.getItem("clientid"),
                "pagename": 'TERMSANDCONDITIONS',
                "pageurl": window.location.href,
                "starttime": presentTime,
                "useragent": JSON.parse(localStorage.getItem("loc"))
    
            };
    
        
        let urlLink;
        if(localStorage.getItem("previousid")){
            urlLink= lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
        }else {
            urlLink= lambda + '/activity?appname=' + appname
        }
    
        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if(response.data.statusCode === 200){
                    localStorage.setItem("previousid",response.data.result)
                }
            });
    }
    function handleClose() {
        if (localStorage.getItem("token")) {
            history.go(-1)
        }
        else {
            history.push("/");
        }
    }

    const { menus } = useContext(contentContext)



    return (
        <>
            {localStorage.getItem("token") ? <InsideHeader /> : <Header menus={menus} />}


            <div className="container">
                <div className="terms-page">
                    <div className="terms-header">
                        {menus && menus.length > 0 && menus.map(function (item, i) {
                            return (
                                item.menuid === 100006 ? <div className="terms-cls-wrapper" key={i}>{ReactHtmlParser(item.content)}</div> : ""
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* <div className="terms-wrapper">
    
    <div className="terms-inner">
   

<p>Orasimedia (the
      “Program”) is a worldwide digital emerging artist showcase
      currently hosted on a server located in Canada, in which emerging recording
      artist(s) (referred to sometimes herein as “you”, a “User” and each, an
      “Artist”,
      and collectively, the “Artists”) participate as follows: </p><ul className="list_number"><li>The Artists agree to display and exhibit their talent, performances, musical
          works, songs, and/or cinematographic works and/or recordings (e.g. music
          videos) (each, a <b>“Work”</b>, and collectively, the <b>“Works”</b>) on the
          Program;</li><li>The Artists agree to order and obtain a digital nuanced analysis (each, a
          <b>“DNA”</b>, and collectively, the <b>“DNAs”</b> ) of their Works, in the
          following amounts and at the
          following price to be paid by the Artists (the “Fee”): <ul className="list_alpha"><li>One (1) DNA for USD $6.95 or IRS 556</li><li>Five (5) DNAs for USD $15.95 or RS 1276</li><li>Fifteen (15) DNAs for USD $25.95 or RS 2076</li></ul></li><li>The Artists hereby engage the services of Orasimedia Inc. (the <b>“Company”</b>)
          to
          exclusively represent them and their Works to potential recording companies,
          producers, professionals, and the public as further set forth herein.</li></ul><p>These Online Terms of Services and Conditions (the <b>“Terms and Conditions”</b>)
      constitute the legal agreement between you and Orasimedia Inc. (the
      <b>“Company”</b>) with respect to your participation in the Program and
      submission of your Works
      to the website available at <a href="#" target="_blank">
          https://deas.Orasimedia.com</a> (the <b>“Online Website”</b>). You
      acknowledge that you have read, understand, and voluntarily agree to and agree
      to abide by these Terms and Conditions in their entirety as well as the online
      privacy policy available at <a href="#" target="_blank"> https://deas.Orasimedia.com/privacy-policy/</a> (the
      <b>“Privacy Policy”</b>).
  </p><p>The effective date of these Terms and Conditions is August 1st, 2022 (the
      <b>“Effective Date”</b> ), and these Terms and Conditions expressly supersede
      any and
      all prior agreements by you and Company with respect to the Program and/or
      the Works. By submitting your Works as set forth in these Terms and Conditions,
      you confirm and agree that you meet the Eligibility Requirements (as hereinafter
      defined) and that the Works meet the Work Submission Requirements set forth
      in section B of these Terms and Conditions.
  </p><p>Works will be accepted commencing on the Effective Date through and including
      NOVEMBER 30TH, 2022 (the <b>“Submission Period”</b>). No Works will be accepted
      after the Submission Period.</p><p>The company makes no guarantee that all Works that it receives will be
      reviewed. As used herein, the <b>“User”</b> will refer to the Artists and the
      individual
      who completes the data fields on the Online Website and uploads the Works.</p><p>The User must be at least eighteen (18) years of age or the age of legal majority
      in the User’s jurisdiction (if greater than eighteen (18) years of age) and, if
      the
      User is registering on behalf of a person under the legal age of majority (each
      a <b>“Minor”</b>), he User must be duly and legally authorized to act on behalf
      of such
      Minor (i.e., the User must be a parent or legal guardian).</p><h5>A. Eligibility Requirements / Fees / Compensation.</h5><p>To participate in the Program, you must satisfy all of the following eligibility
      requirements (collectively, the <b>“Eligibility Requirements”</b>)
      and be able to prove
      all of the following, all to the satisfaction of the Company within its sole and
      exclusive discretion:</p><ul className="list_number"><li>By the Effective Date, you must be of the age of majority in your country of
          residence and at least eighteen (18) years of age. If you are not at least
          eighteen
          (18) years of age by the Effective Date, or if you are ever in breach or
          default of
          any payment, term, or condition under the Terms and Conditions or pursuant
          to
          any other agreement with the Company you are not and shall not be eligible
          to
          participate in the Program.</li><li>To be able to display and publish your Works as part of the Program
          (currently
          scheduled to begin on the Effective Date), you must have paid the Fee and
          all
          amounts due for the submission of the Works and services rendered for or to
          you
          and also be able to demonstrate that you do not have any legal restrictions
          or
          contractual arrangements that would prohibit you from fully participating in
          the
          Program, showcase, and/or entering into any contracts and/or agreements
          required by Company, which may include, without limitation, the
          Representation
          Agreements (as hereinafter defined) (subject to paragraph 4 below). The
          Company does not provide refunds, which you expressly acknowledge.</li><li>In the event that you already have a management contract prior to your
          submission of the Work, the requirement to enter into a management contract
          shall not apply to you provided that you can demonstrate that such contract
          was
          executed prior to the date of your initial upload of the Work. By uploading
          and
          submitting the Work, you must timely complete and return all such rights
          agreements, releases, and all exhibits, schedules, and attachments thereto
          as
          may be furnished to you by Company, from time to time, within their sole and
          exclusive discretion. </li><li>The company shall have full discretion to select the Artists that Company
          may
          wish to represent, manage, and introduce to its contacts and customers. The
          company will rely on its professional expertise, taste, judgment,
          discretion, and
          DNAs to select such Artists. The Artists selected will then be requested to
          enter
          into certain or all of the Representation Agreements. The Artists shall be
          free to
          consult with independent legal advisors and to accept and sign such
          Representation Agreements.</li><li>Notwithstanding the signature of any Representation Agreement, each of the
          Artists agrees that the fees of the Company for administering the publishing
          rights granted in respect of the Works shall be an amount of 15% of all
          gross
          publishing revenues in addition to 50% of the publishing revenues which
          shall be
          earned and paid to Company as the publisher of the Works from all
          territories,
          worldwide and all media and formats now known or hereinafter discovered. In
          addition, if a professional engagement is obtained or if the Artists earn or
          receive
          any income, revenue or receipts by their submission of the Works or by
          Company`s efforts or contacts, including any recording agreements, they
          agree
          that Company shall be entitled to receive 18% of such revenues and receipts
          as
          a fee for the services and platform provided through the Program.</li><li>As of the Effective Date and continuing through the completion or
          termination of
          the Program, neither you nor any of your immediate family members or
          members of your household (whether related or not), maybe an employee,
          officer, or director of (a) The Company and all majority-owned subsidiaries
          thereof; (b) any of the foregoing entities` respective parents,
          subsidiaries, or
          affiliated companies or entities; (c) any person or entity involved in the
          casting,
          auditions, development, production, distribution, or other exploitation of
          the
          Program or any variation thereof; or (d) any known, major sponsor of the
          Program (collectively, the
          <b>“Program Entities”</b>).
      </li><li>You must accurately complete all Representation Agreements and other
          documents provided by the Company, any of the other Program Entities, or any
          other entity providing services to the Program. The company has the sole
          discretion to make determinations of eligibility, which are binding and
          final in all
          respects, and Company reserves the right to change any of the Eligibility
          Requirements at any time. In addition, you acknowledge and agree that
          Company has the sole discretion at any time to render ineligible any person
          who,
          in the Company`s sole discretion, is sufficiently acquainted with the
          development, production, administration, judging, exhibition, or other
          exploitation of the Program such that his or her participation in the
          Program
          could create the appearance of and/or the existence of an actual a conflict
          of
          interest or impropriety.</li></ul><h5>B. Work Submission Requirements.</h5><ul className="list_number"><li>General Requirements. <ul className="list_alpha"><li>Your Works should be submitted to the Online Website at <a href="https://deas.Orasimedia.com/?ref=FINALCOAST" target="_blank">
                      http://deas.Orasimedia.com</a> or such other sites as authorized by
                  the Company from time to time, including
                  those websites operated by Program Entities, sponsors, or partners
                  of the
                  Company. Follow the onscreen prompts to upload your Works. Your
                  Works will
                  NOT be accepted after the Submission Period. </li><li>You (or your parent or legal guardian, if you are a Minor) are
                  responsible for
                  obtaining the compatible hardware or devices and must have Internet
                  access
                  during the Submission Period and sign up as the User on the Online
                  Website. The
                  company does not guarantee that you will be able to access the
                  Program on any
                  particular hardware or device. </li><li>The Works MUST be submitted by a registered User who is at least
                  eighteen (18)
                  years of age or the age of legal majority in User`s jurisdiction (if
                  greater than
                  eighteen (18) years of age), which User shall be either you or if
                  you are a Minor,
                  your parent or legal guardian. </li><li>Your Works must comply with the requirements of this section B of
                  the Terms
                  and Conditions. Any Works that, at the Company`s discretion, do not
                  comply with
                  the requirements of this section B of the Terms and Conditions may
                  be excluded
                  at Company`s sole discretion.</li><li>Your Works submission will not be complete until you have accepted
                  these
                  Terms and Conditions and the Privacy Policy available at <a href="#" target="_blank">
                      http://deas.Orasimedia.com/privacy-policy/</a>.</li><li>The company reserves the right to determine, in its sole discretion,
                  whether you
                  have satisfied the Eligibility Requirements and if you are eligible
                  to participate in
                  the Program.</li></ul></li><li>The Works <ul className="list_alpha"><li>Your Works must conform to the requirements of these Terms and
                  Conditions,
                  and the Privacy Policy available at <a href="#" target="_blank">
                      http://deas.Orasimedia.com/privacy-policy/</a>. </li><li> Your Works must NOT include a mention, feature, include, use,
                  incorporate, or
                  integrate any copyrighted material or copyrighted works unless such
                  copyrighted
                  material or copyrighted works, as applicable, have been duly and
                  properly
                  licensed and cleared (collectively, the <b>“Permissible Licensed
                      Material”</b>).</li><li>Your Works shall not infringe the privacy rights of any person or
                  entity, including,
                  without limitation, the privacy rights in and to their image. </li><li>The Works must NOT contain material that is obscene, pornographic,
                  discriminatory (based on race, sex, religion, national origin,
                  physical disability,
                  sexual orientation, age, or any ground protected against
                  discrimination under
                  the law applicable to these Terms and Conditions), illegal (for
                  example, underage
                  drinking, substance abuse, computer hacking, etc.), offensive,
                  threatening,
                  profane, harassing.</li><li>The Works must NOT contain defamatory, derogatory, or negative
                  statements or
                  references (whether explicit or implied) to the Program Entities.
              </li></ul></li></ul><h5>C. Selection</h5><p>If you are selected for representation by Company, you may be required to
      provide or arrange for your own travel to a location selected by Company in its
      sole discretion. If you are selected for representation, and if you will be
      under
      eighteen (18) years of age or the age of legal majority in your jurisdiction (if
      greater than eighteen (18) years of age) on the day you are asked to report to
      company s selected location, your parent and/or legal guardian MUST
      accompany you. In addition, in the latter case, your parent or legal guardian
      must bring two (2) pieces of government-issued photographic identification (for
      example, a driver s license). If you are accompanied by a legal guardian, as
      opposed to a parent, your legal guardian must bring proof of guardianship (for
      example, a court order). </p><h5>D. Further Documentation.</h5><p>In the event Company chooses to represent you, you will be required by the
      company to fill out, sign, and agree to, all of the terms and conditions of such
      additional forms and agreements as are required by the Company, which may
      include, without limitation, a participant agreement release and confidentiality
      agreement (the “Release”), an exclusive publishing contract, agency contract,
      management contract, recording contract, merchandising contract, live and/or
      global touring contract, online content contract, and other agreements necessary
      for its representation, if any, of you (collectively, the “Representation
      Agreements”). In addition, you (as well as parents and/or legal guardians in the
      case of Minor Artists) may be required to complete an extensive background
      questionnaire and may be subject to a thorough background investigation and
      other examinations, at Company’s discretion. If you are a Minor, both you and
      your parent or legal guardian must sign each of the forms and agreements,
      including, without limitation, the Representation Agreements, and cooperate in
      having said forms and agreements court approved, if and when required and
      requested by Company. If you are a Minor and accompanied by a guardian
      appointed by your parent or legal guardian, you must have the Release signed
      and notarized by your parent or legal guardian with the authority to sign on
      your
      behalf and bring the signed and notarized Release with you to the location. Your
      family and/or guests should be prepared to sign releases concerning the possible
      taping and/or recording of their name, image, likeness, voice, conversation,
      etc.</p><h5>E. Exclusions and Termination.</h5><ul className="list_number"><li>The company reserves the right to exclude and terminate, in its sole and
          absolute discretion, any individual, including, without limitation, you,
          from the
          Program for any reason or for no reason at all, including without
          limitation,
          violation of any portion of these Terms and Conditions. Without limiting the
          generality of the foregoing, the Company reserves the right to exclude and
          terminate, in its sole and absolute discretion, any individual, including,
          without
          limitation, you, from the Program: <ul className="list_alpha"><li> That breaches or violates any term or condition of these Terms and
                  Conditions, including, without limitation: (i) the Work Submission
                  Requirements; and (ii) the representations and warranties set forth
                  in these Terms and Conditions;</li><li>That prior to, during, or after the Submission Period fails,
                  refuses, or neglects to act with due regard to social conventions
                  and public morals and decency, or commits any act in contravention
                  of any public morals and decency which brings Company and/or the
                  Program Entities into disrepute, scandal, contempt or ridicule which
                  shocks, insults, or offends a substantial portion or group of the
                  community or reflects unfavorably on Company and/or the Program
                  Entities, the whole as determined by Company at its full discretion.
              </li></ul><ul></ul></li><li>In the event that the Company excludes or terminates you from the Program
          pursuant to section E.1 of these Terms and Conditions above, you are
          prohibited
          from participating in the Program again, whether under your name, a fake or
          borrowed name, or another name.</li></ul><h5>F. Publishing Rights / License.</h5><ul className="list_number"><li>In the event that you are the author, owner, or composer of any underlying
          song,
          music, or work embodied in the Work or Works, then, by uploading the Works,
          Artists hereby grant to Company all of their publishing rights to the Works
          and all
          underlying rights to the Works, being all right, title and interest in the
          copyright
          to the song, music, lyrics or other works underlying or embodied in the
          Works for
          the full term of copyright and any extensions or renewals. In addition, by
          submitting your Works, you hereby grant to Company, in perpetuity, a non-
          exclusive, royalty-free, sub-licensable, and freely transferable license to
          use,
          reproduce, distribute, prepare derivative works of, display, adapt,
          reformat,
          translate, and otherwise exploit and perform all or any portion of the Works
          for
          any purpose whatsoever, throughout the universe in perpetuity, in all media
          whether now known or hereinafter devised, including, without limitation, in
          connection with: <ul className="list_alpha"><li>The Program, including, without limitation, motion pictures,
                  television, and
                  publishing, and in connection with the advertising (including at
                  physical
                  locations), sale, promotion, publicity, marketing, merchandising,
                  distribution,
                  publicizing, and any and all other types of exploitation of the
                  Program or any part
                  thereof (including without limitation in connection with Program
                  advertisers
                  and/or sponsors and any of their respective products, integrations
                  and/or
                  services) and of any and all ancillary and subsidiary rights, and
                  redistributing
                  part or all of the Program (and derivative works thereof).</li><li> The business of Company, applications, interactive television,
                  video on demand,
                  and any other media or technology (including, without limitation,
                  Internet
                  protocol, wireless or interactive platforms or interfaces) through
                  which Company
                  may distribute content to end users, whether now existing or
                  hereafter
                  developed (collectively, the “Platforms”), in any and all media
                  formats and
                  through any and all media distribution and advertising and
                  promotional
                  purposes, without accounting, notification, credit or other
                  obligation to you.</li></ul></li><li>For the avoidance of doubt, without limiting the generality of the rights
          granted
          to Company and/or any Program Entities, these rights include, without
          limitation,
          the absolute right to edit and/or alter your Works; distribute and
          synchronize all
          or any portion of your Works in timed relation to any other visual elements;
          to
          web cast, podcast, re-publish, re-telecast, re-platform, port, syndicate,
          route, and
          link to and from all or any portion of your Works; to encrypt, encode and
          decode,
          and compress and decompress all or any portion of your Works; to edit, mix,
          combine, merge, distort, superimpose, create or add special effects,
          illusions
          and/or other material to or of all or any portion of your Works; to create
          composite, stunt, comic or unusual photographs, videos, animations, motion
          pictures and/or voice reproductions from all or a portion of your Works; and
          to
          excerpt and/or extract portions of your Works in order to host, store,
          index,
          categorize and display your Works on or through the Platforms. In this
          regard,
          you hereby waive any moral rights in favor of the Company to the maximum
          extent permitted by law.</li><li>You further grant Company and their respective parents, subsidiaries,
          related
          entities or affiliates, successors, licensees and assigns the irrevocable
          right (but
          not the obligation) to and to license others to film, tape, and/or
          photograph,
          record, exhibit, edit and otherwise use your name, voice, sounds, image,
          likeness
          and biographical information in and in connection with the Program in any
          manner in Company s sole election and discretion, including in connection
          with
          advertising (including at physical locations), sale, promotion, programming
          and
          publicity materials, marketing, merchandising, distribution, publicizing and
          any
          and all other types of exploitation of the Program or any part thereof
          (including,
          without limitation, in connection with commercial tie-ins, Program
          advertisers
          and/or sponsors and any of their respective products, integrations and/or
          services) throughout the universe, in any and all media, now known or
          hereafter
          devised, in perpetuity.</li><li> You also grant to Company, any of their respective parent, subsidiary,
          related
          entities or affiliates, sponsors of the Program, the rights set forth in
          this section F
          for the institutional purposes of Company and/or their respective affiliated
          entities, throughout the world, in any and all media, now known or hereafter
          devised, in perpetuity.</li><li> You understand that any use as described herein shall not entitle you to
          receive
          any compensation whatsoever. The company has no obligation to review your
          Works or to use your Works in any manner whatsoever. The company has the
          right, but not the obligation to use any of the rights granted by you
          herein.</li></ul><h5>G. Representations and Warranties / Indemnification.</h5><p>You represent and warrant that:</p><ul className="list_number"><li>All information provided under and in connection with these Terms and
          Conditions, under any form or other agreement in connection with the
          Program,
          including, without limitation, the Representation Agreements, and in
          connection
          with the Program shall be complete and truthful. You understand that if you
          fail
          to complete any form or agreement requested by Company in connection with
          these Terms and Conditions or in connection with the Program, or provide
          false
          or misleading information, or fail to disclose any pertinent information,
          you may
          be excluded from and terminated from participation in the Program.</li><li>Except for Permissible Licensed Material: (i), You are the sole and
          exclusive
          author and owner of One Hundred Percent (100%) of the copyright in and to
          the
          Work; (ii) the Works are your own previously unpublished works; (iii) as of
          the
          date of submission of the Works, the Works are not the subject of any actual
          or
          threatened litigation, claim, or complaint.</li><li>The Works neither infringe upon nor violate the intellectual property
          rights,
          including, without limitation, copyright, or other rights of any other
          person or
          entity.</li><li>The Works do not and will not violate any applicable laws and fully
          satisfies the
          Work Submission Requirements set forth in section B of these Terms and
          Conditions. </li><li>You will, upon request, promptly furnish to Company any documentation,
          agreements, substantiation, and releases necessary and reasonably required
          in
          connection with Permissible Licensed Material and identifiable locations to
          prove,
          confirm, verify, and substantiate your compliance with any or all of these
          Terms
          and Conditions, including, without limitation, the foregoing representations
          and
          warranties. In the event, you cannot provide all required documentation,
          agreements, substantiation, and releases, Company reserves the right, in its
          sole
          discretion, to discard or remove your Works or seek to secure the
          documentation, agreements, substantiation, and releases and clearances for
          the
          benefit of Company and/or the Program Entities or may allow the applicable
          Works to be utilized. Additionally, you represent and warrant that you will
          not
          acquire any trademark rights, copyrights, or any other rights in the
          trademarks,
          trade names, logos, or other intellectual property of the Company or any
          other
          Program Entities. Each Program Entity specifically reserves its respective
          right to
          protect its intellectual property against any infringement or other illegal
          or
          improper use.</li><li>You have the right, authority, and capacity to enter into these Terms and
          Conditions and all documentation, contracts, agreements, substantiation, and
          releases that may be entered into in connection therewith, including,
          without
          limitation, the Representation Agreements.</li></ul><h5>H. Conditions of Participation.</h5><ul className="list_number"><li>This Program is subject to all applicable laws.</li><li>You agree to indemnify and hold harmless the Company, the Program Entities,
          their respective affiliates, partners (including, without limitation,
          advertising and
          promotion partners), fulfillment and/or judging agencies, retailers,
          franchisees,
          agents, webmasters/ suppliers, contractors, vendors, and their licensees,
          successor, assigns, estate, and from and against any and all third party
          claims,
          actions or proceedings of any kind and from any and all damages,
          liabilities,
          costs, and expenses relating to or arising in whole or in part, directly or
          indirectly, out of (i) your participation in the Program or a related
          activity or in
          connection with these Terms and Conditions; (ii) Any breach or alleged
          breach of
          these Terms and Conditions.</li><li>You waive any right to claim ambiguity in the Program or these Terms and
          Conditions. The company reserves the right to remove suspect Works, and/or
          cancel, terminate or suspend any portion of this Program at any time and for
          any
          reason (or no reason at all) at their absolute discretion.</li></ul><h5>I. Privacy Policy.</h5><p>Any personal information supplied by you in connection with the Program will be
      subject to the Privacy Policy available at <a href="#" target="_blank">
          http://deas.Orasimedia.com/privacy-policy/</a>. By participating in the Program,
      you expressly grant the Program Entities
      permission to share your name, address, email address, and any other
      personally identifiable information with the other program Entities for the
      purpose of this Program.</p><h5>J. Cookie Policy.</h5><p>You hereby agree to and accept the Cookie Policy available at <a href="#" target="_blank">
          http://deas.Orasimedia.com/cookie-policy/</a>.</p><h5>K. Digital Millennium Copyright Act Policy.</h5><p>You hereby agree to and accept the Digital Millennium Copyright Act Policy
      available at <a href="#" target="_blank">
          http://deas.Orasimedia.com/digital-millennium-copyright-act-policy/</a>.</p><h5>L. Acceptable Use Policy.</h5><p>You hereby agree to and accept the Acceptable Use Policy available at <a href="#" target="_blank">
          http://deas.Orasimedia.com/acceptable-use-policy/</a>. his Acceptable Use Policy
      is in
      addition to the terms and provisions of these Terms and Conditions. </p><h5>M. Other Pitch Materials.</h5><p>You understand and acknowledge that the Company and the Program Entities
      have wide access to ideas, concepts, stories, designs, copyrightable works and
      materials (each, the “Pitch Material”, and collectively, the <b>“Pitch
          Materials”</b>),
      and that <b>“Pitch Materials”</b> are constantly being submitted to the Company
      and the
      Program Entities or being developed by their own respective employees. You
      acknowledge that Pitch Materials be competitive with, similar, or identical to
      the
      Works and/or each other in theme, idea, format, expression, and/or other
      respects. You acknowledge and agree that you will not be entitled to any
      compensation as a result of the use by the Company and the Company and the
      Program Entities of any Pitch Materials. You acknowledge and agree that the
      Company and the Program Entities do not now and shall not have in the future
      any duty or liability, direct or indirect, vicarious, contributory, or
      otherwise, with
      respect to the infringement or protection of the copyright and other rights in
      and
      to the Works.</p><h5>N. Limitations on Liability.</h5><ul className="list_number"><li>To the maximum extent permitted by law, your remedy for any breach of these
          Terms and Conditions shall be limited to an action at law for compensatory
          money damages, and the compensatory money damages shall be limited to the
          actual registration fee incurred in connection with the Program, including
          reasonable bona fide out-of-pocket outside costs associated with
          participating in
          the Program. In no event shall you be entitled: (i) To seek or recover
          punitive or
          exemplary damages, incidental or consequential damages, or attorneys`` fees,
          all of which shall not be recoverable; (ii) to seek or obtain injunctive or
          equitable
          relief; (iii) to seek to or actually interfere with, delay, or prevent the
          continuation
          of the Program and/or the exploitation, distribution, advertising, and
          promotion
          of any Works; (iv) rescind these Terms and Conditions or any agreement,
          contract, document, or release entered into in connection therewith or in
          connection with the Program, including, without limitation, the
          Representation
          Agreements. You acknowledge and agree that notwithstanding anything to the
          contrary set forth in these Terms and Conditions, the Company and Program
          Entities shall be entitled to seek and obtain injunctive and other equitable
          relief.
          Claims under these Terms and Conditions may only be brought in an individual
          capacity and not as a plaintiff or class member in any purported class,
          representative, or multi-claimant proceeding. Furthermore, unless the
          Company
          agrees in writing, the arbitrator may not consolidate other persons` claims
          with
          yours, and may not otherwise preside over any form of a representative,
          multi-
          claimant, or class proceeding.</li><li>Without limiting the generality of the foregoing or anything else set forth
          in this
          Agreement, you acknowledge and agree that the Company, the Program Entities,
          their affiliates, partners (including, without limitation, advertising and
          promotion
          partners), fulfillment, and/or judging agencies, retailers, franchisees,
          agents,
          webmasters/ suppliers, contractors, vendors and their licensees, successor,
          assigns, estate, and/or family members, are not responsible for any costs,
          injuries, losses, or damages of any kind arising from or in connection with:
          <ul className="list_alpha"><li>Incomplete, lost, late, misdirected, or illegible submissions or for
                  failure to
                  receive or review submissions due to any cause, including, without
                  limitation,
                  human, transmission, or technical problems, failures, or
                  malfunctions of any
                  kind, whether originating with you, with the Program Entities or
                  otherwise, that
                  may limit a participant`s ability to participate in the Program;
              </li><li>Incorrect or inaccurate information whether caused by Internet
                  users, or by any
                  equipment or programming associated with or utilized in connection
                  with the
                  Program, and the Program Entities assume no responsibility for any
                  error,
                  omission, malfunction, interruption, deletion, defect, or delay in
                  operation or
                  transmission, communications line failure, theft, or destruction or
                  unauthorized
                  access to, tampering, or hacking;</li><li> Any injury, prejudice, or damage resulting directly or indirectly,
                  in whole or in
                  part, from participation in the Program (including, without
                  limitation, claims,
                  costs, injuries, losses, and damages related to personal injuries,
                  death, damage
                  to, loss or destruction of property, or any claims, costs, injuries,
                  losses, or
                  damages related to or based on the participant`s rights of publicity
                  or privacy);
                  or</li><li>Any claim by you for defamation or portrayal in a false light.</li></ul></li><li>Without limiting the generality of the foregoing, the Company and the
          Program
          Entities assume no responsibility for any damage to your computer system,
          which is occasioned by accessing the Online Website, or participating in
          this
          Program, or for any computer system, phone line, hardware, software or
          program malfunctions, or other errors, failures, delayed computer
          transmissions
          or network connections that are human or technical in nature, or for the
          incorrect
          or inaccurate capture of information, or the failure to capture any
          information. If,
          for any reason, this Online Website is not capable of operating as planned
          by
          reason of infection by computer virus, worms, bugs, tampering, hacking,
          unauthorized intervention, fraud, technical failures or any other causes
          which, in
          the sole opinion of Company, corrupt or affect the administration, security,
          integrity or proper conduct of the Online Website, Company and the Program
          Entities reserve the right, at their absolute discretion, to close the
          Submission
          Period.</li></ul><h5>O. Severability, No Waiver, Assignment, No Joint Venture or Partnership.</h5><p>If any provision of these Terms and Conditions is held to be invalid or
      unenforceable, such provision shall be struck and the remaining provisions shall
      be enforced to the fullest extent of the law. Company`s and/or the Program
      Entities`` failure to enforce any right or provision in these Terms and
      conditions
      shall not constitute a waiver of such right or provision unless acknowledged and
      agreed by Company in writing. You may not assign these Terms and conditions
      in whole or in part without Company`s prior written approval. Company may
      assign these Terms and Conditions without your consent, including, without
      limitations, to (i) a subsidiary or affiliate; (ii) an acquirer of the Company`s
      equity, business, or assets; or (iii) a successor by merger. Any purported
      assignment in violation of this section shall be void ab initio. No joint
      venture,
      partnership, employment, or agency relationship exists between you, the
      company, and the Program Entities as a result of these Terms and Conditions.</p><h5>P. Notice.</h5><p>The company may give notice by means of electronic mail to your email address
      or by written communication sent to your address. You may give notice to
      Company by a written communication by email to <a href="#">
          info@Orasimedia.com.</a> </p><h5>Q. Questions.</h5><p>Please contact the Company with any questions regarding these Terms and
      conditions and/or the Program by emailing the Company at <a href="#">
          info@orasimedia.com.</a> </p><h5>R. Amendments.</h5><p>The company reserves the sole and exclusive right within its discretion to modify
      or change these Terms and Conditions from time to time and such modifications
      will be effective upon posting to the Online Website. Your continued use of and
      participation in the Program after such posting confirms your consent to be
      bound by the Terms and Conditions, as amended.</p><h5>S. Choice of Law.</h5><p>The Terms and Conditions shall be governed by the laws of the Province of
      Quebec and the federal laws of Canada applicable therein. Any controversy or
      claim arising out of or relating to the Terms and Conditions, if it cannot be
      settled by direct discussions, shall be settled by arbitration in accordance
      with
      the provisions contained in the Code of Civil Procedure (Quebec) and judgment
      upon the award shall be entered in a court of the Province of Quebec in the city
      of Montreal.</p><h5>T. Language.</h5><p>The parties hereto have requested that these terms and conditions and all
      communications be drafted in the English language. <i> Les parties aux présentes
          ont exigé que ces termes et conditions et toutes communications soient
          rédigés
          en langue anglaise. </i> </p>
    </div>


  </div> */}
            <Footer menus={menus} />


        </>
    );
};

export default Terms;
