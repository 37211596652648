/***
**Module Name: not found
 **File Name :  notfound.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains page not found details.
 ***/
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router";
import * as Config from "./../constants/Config";

const NotFound = () => {
    const [config, setConfig] = useState({});
    const history = useHistory();


    useEffect(() => {
        if(window.site){
          setConfig(window.site);
         
        }
       
      }, [window.site]);
    
      if(config.common && config.common.imagesCDN ){
        var img = config.common.imagesCDN;
      }


    const handleBack = () => {
        if (!localStorage.getItem("token")) {
            history.push("/");
          }else{
            // history.push("/category");
            history.go(-1)
          }
       
    }
    return (
        <>
           
            <main>
            <div className="error-wrapper">
    <h1>Error!</h1>
    <h3>There is nothing here</h3>
    <img src={img+"orasi/client/resources/orasiv1/images/error404.svg"} className="error-img" /><br/>
    <button className="fill_btn yellow-gradient push-center mt-3" onClick={handleBack}>BACK TO HOME</button>
  </div>

            </main>
         


        </>
    );
};

export default NotFound;
