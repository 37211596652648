/***
**Module Name: loginotp
 **File Name :  loginotp.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains login otp validation page details.
 ***/
import React, { useState, useEffect, useRef, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory } from "react-router";
// import "../../src/assets/css/style.css"
import * as Config from "../constants/Config";
import OTPInput from "otp-input-react";

import Footer from "../components/footer/Footer";
import $ from 'jquery';
import { contentContext } from "../context/contentContext";


const SignInOtp = () => {
  const history = useHistory();
  const [mpin, setInput] = useState({});
  const [otp, setOtp] = useState('');
  // const [menus, setMenus] = useState([]);
  const [error, setError] = useState([]);
  const [config, setConfig] = useState({});

  const [activeLoad, setActiveLoad] = useState("");
  const [resendActive, setResendActive] = useState(false);
  const [otpclass, setOtpClass] = useState(false);
  const one = useRef();
  const two = useRef();
  const three = useRef();
  const four = useRef();
  const five = useRef();
  const [locData,setLocData] = useState("");
  useEffect(() => {
    // appMenus();
    setLocData(JSON.parse(localStorage.getItem("loc")));
  }, []);
  const { menus,GetClientDataFunction } = useContext(contentContext);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/content");
    }
    if (window.site) {
      setConfig(window.site);

    }
    if(localStorage.getItem("email") == null ||localStorage.getItem("email") == "null"){
      history.push('/login')
    }

  }, [window.site]);

  if (config.common && config.common.imagesCDN) {
    var img = config.common.imagesCDN;
  }

  // const appMenus = async () => {
  //   try {
  //     console.log(tmdbApi);
  //     const response = await tmdbApi.appMenus({result : "micro"});
  //     setMenus(response.result)
  //     console.log(response);
  //   } catch {
  //     console.log("error");
  //   }
  // };



  // const updateInputValue = (e) => {
  //   const val = e.target.value;
  //   let updatedValue = {};
  //   updatedValue = { [e.target.name]: val };
  //   setInput(mpin => ({
  //     ...mpin,
  //     ...updatedValue
  //   }));
  //   if (e.target.name == "one") {
  //     two.current.focus();
  //   } else if (e.target.name == "two") {
  //     three.current.focus();
  //   } else if (e.target.name == "three") {
  //     four.current.focus();
  //   } else if (e.target.name == "four") {
  //     five.current.focus();
  //   } else if (e.target.name == "five") {
  //     if (mpin.one && mpin.two && mpin.three && mpin.four && val) {
  //       let mpinValue = mpin.one + mpin.two + mpin.three + mpin.four + val
  //       console.log(mpinValue);
  //       setOtp(mpinValue);
  //     }
  //   }
  // }

  function handleClose() {
    history.push("/");
  }
  function handleEmailLogin() {
    history.push("/login")
  }

  const handleLogin = (e) => {
    
    console.log("otp", otp);
    if (otp === "" || otp === null) {
      setError("Please Enter Otp");
      setTimeout(function () { setError("") }, 3000);
      setActiveLoad("")
    } else {
      console.log("signOtp");
      if(activeLoad == ""){
      signOtp();}
    }
    localStorage.removeItem("loginuser");

  }
  const signOtp = async () => {
   
    try {
      setActiveLoad("loginOtp")
      const response = await tmdbApi.signOtp({
        "emailid": localStorage.getItem("email"),
        "logintype": "otp",
        "otp": Number(otp),
        "useragent" : locData?.headers !== undefined ? locData?.headers : {}
      });
      console.log(response);
      if (response.result.res === "password not set") {
        localStorage.setItem("clientid", response.result.clientid);
        history.push("/generatepassword/?id="+response.result.clientid);
      }else if(localStorage.getItem("check") && response.statusCode === 200){
        console.log("came");
        localStorage.setItem("token", response.result.token);
        localStorage.setItem("clientid", response.result.clientid);
        localStorage.setItem("login", "setlogin");
        let currentDate = new Date().toJSON();
        localStorage.setItem("currentSessionClientTime", currentDate);
        GetClientDataFunction();
        history.push("/moreinfo/" + localStorage.getItem("check"));
    } else if (response.result.token) {
        console.log("came");
        localStorage.setItem("token", response.result.token);
        localStorage.setItem("clientid", response.result.clientid);
        let currentDate = new Date().toJSON();
        localStorage.setItem("currentSessionClientTime", currentDate);
        history.push("/content");
      } else {
        // setError(response.result);
        // setTimeout(function () { setError("") }, 3000);
        // var element = document.getElementById("otp");
        // element.classList.add("invalid");
        // setTimeout(function () { 
        // element.classList.remove("invalid");
        // setOtp("")
        // one.current.value="";
        // two.current.value="";
        // three.current.value="";
        // four.current.value="";
        // five.current.value="";
        //  }, 3000);
        setOtpClass(true)
        setActiveLoad("")
      }
    } catch {
      setActiveLoad("")
      console.log("error");
      // var element = document.getElementById("otp");
      // element.classList.add("invalid");

      //   setTimeout(function () { 
      //     element.classList.remove("invalid");
      //   setOtp("")
      // }, 3000);
    }
  };

  const handleResendOtp = async () => {
    setResendActive(true)
    setTimeout(function(){
      setResendActive(false)
    },3000)
   // setOtpClass(true)
    try {
      console.log(tmdbApi);
      const response = await tmdbApi.resendMail({
        "emailid": localStorage.getItem("email"),
        "type": "signin"
      });
      // setResendActive(false)
      console.log(response);
    } catch {
      setResendActive(false)

      console.log("error");
    }
  };

  const handleChange = (otp) => {
    setOtpClass(false);
    setOtp(otp)
  };
  const handleOtpError = (e) => {
    console.log("came");
    // var element = document.getElementById("otp");
    // element.classList.remove("invalid");
    setOtpClass(false)
  }

  const cardWidth = $(".signin-graphic").width();

  console.log("otp", otp);

  return (
    <>
      <button className="close-btn" onClick={handleClose}><span className="material-icons">close</span></button>
      <div className="signin-wrapper">
        <a href="/">
          <img src={img+"orasi/client/resources/orasiv1/images/logoupdated.png?auto=compress,format"} className="logo" /></a>
        <div className="signin-body">
          <div className="signin-graphic">
            <h2>Welcome to<br />Digital Content Rights<br />Marketplace</h2>
            <img src={img + Config.imgmiddle + "signin-graphic.png?auto=compress,format&width=" + cardWidth} />
          </div>

          <div className="signin-section emaillogin ">
            <h1 className="bold-heading">Login</h1>

            <p>Enter 5 Digit OTP sent to your Email</p>
            <p>{localStorage.getItem("email")}</p>
            <div className="otp-blocks mt-5 mb-5  ">
            <div className={otpclass ? "otp-inputs block-margin invalid errorStyle" : "otp-inputs block-margin" } id="otp">

                {/* <input type="text" name="one" ref={one} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" autoFocus />
                <input type="text" name="two" ref={two} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                <input type="text" name="three" ref={three} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                <input type="text" name="four" ref={four} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" />
                <input type="text" name="five" ref={five} autoComplete="off" onChange={(e) => updateInputValue(e)} className="form-control" maxLength="1" /> */}

                <OTPInput value={otp}
                  // onChange={setOtp}
                  onChange={handleChange}
                  autoFocus OTPLength={5} otpType="number" onFocus={(e) => handleOtpError(e)} />
                <p>The OTP you have entered is incorrect. Please try again or request a new OTP</p>
              </div>
              <button className="resend-button" onClick={handleResendOtp}>Resend</button>{resendActive ?
                <p style={{ 'font-size': '12px', 'color': 'green', 'position': 'absolute', 'left': '0', 'bottom': '-29%' }}>OTP Resent!</p>
                : null}
            </div>

            <div className="signin-footer mt-4">

              <button className="fill_btn yellow-gradient" onClick={e => handleLogin(e)}>
                {activeLoad === "loginOtp" ? (<img src={img+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}
                LOGIN</button>
              <button className="sm-btn" onClick={handleEmailLogin}>Login with Password</button>
            </div>

            {error ? <span className="errormsg" style={{
              fontWeight: 'bold',
              color: 'red',
            }}>{error}</span> : ""
            }

            {/* <p className="signup-prompt mt-5">Not Registered Yet?<a href="/signup" className="mx-2">Sign Up</a></p> */}

          </div>

        </div>
      </div>
      <Footer menus={menus} />
    </>
  );
};

export default SignInOtp;
