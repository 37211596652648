/***
**Module Name: Partners
 **File Name :  partners.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 27 2022
 **Last modified on: Dec 27 2022
 **Description : contains team page details.
 ***/
import React, { useEffect, useState, useContext } from "react";

// import "../../src/assets/css/style.css"
import tmdbApi from "../api/tmdbApi";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";
import axios from "axios";

import * as Config from "../constants/Config";
import { contentContext } from "../context/contentContext";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import InsideHeader from "../components/InnerHeader/Header";
let { lambda, appname } = window.app;

const Partners = () => {

  const [config, setConfig] = useState({});
  const [teamBoard, setTeamBoard] = useState({});
  const [teamMember, setTeamMember] = useState({});
  const [about, setAbout] = useState('');
  const { menus, setContentSearchFlag, contentSearchFlag, setRoute } = useContext(contentContext);
  const [showDescription, setShowDescription] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  let img = window.app.img;
  useEffect(() => {
    if (window.site) {
      setConfig(window.site);

    }
    setContentSearchFlag(false)
    setRoute("notcontent")
  }, [window.site]);

  if (config.common && config.common.imagesCDN) {
     img = config.common.imagesCDN;
  }

  useEffect(() => {
    {
      menus && menus.length > 0 && menus.map(function (item, i) {
        if (item.menuid === 100003) {
          setTeamBoard(item.team[0].members);
          setTeamMember(item.team[1].members)
        }
      })
    }

  }, [menus]);

  useEffect(() => {
    getPartners()
    clientActivity()
  }, [])
  const clientActivity = () => {
    let path = window.location.pathname.split("/");
    // const pageName = path[path.length - 1];
    var presentTime = moment();
    let payload;

    payload = {
      "clientid": localStorage.getItem("clientid"),
      "pagename": 'PARTNERS',
      "pageurl": window.location.href,
      "starttime": presentTime,
      "useragent": JSON.parse(localStorage.getItem("loc"))

    };


    let urlLink;
    if (localStorage.getItem("previousid")) {
      urlLink = lambda + '/activity?appname=' + appname + "&previousid=" + localStorage.getItem("previousid")
    } else {
      urlLink = lambda + '/activity?appname=' + appname
    }

    axios({
      method: 'POST',
      url: urlLink,
      data: payload
    })
      .then(function (response) {
        if (response.data.statusCode === 200) {
          localStorage.setItem("previousid", response.data.result)
        }
      });
  }

  const getPartners = async () => {
    try {
      setBtnLoader(true)
      axios({
        method: 'POST',
        url: lambda + '/partners?appname=' + appname,
        // data: payload
      })
        .then(function (response) {
          console.log('response', response)
          if (response?.data?.statusCode === 200) {
            let data = response?.data?.result
            if (data === "Clients not found") {
              setPartnersData([])
            } else {
              setPartnersData(data)
            }

            setBtnLoader(false)
          }
        });
    } catch (error) {
      setBtnLoader(false)
      console.log('error', error)
    }
  }
  const handleDescription = (e, item) => {
    setAbout(item)
    setShowDescription(!showDescription)
  }

  //  const img= "https://orasi.imgix.net/orasi/client/resources/orasiv1/images";
  const cardWidth = $(".team-card-img-block").width();
  const cardWidth1 = $(".team-card-thumb").width();
  return (
    <>
      <Modal show={showDescription} onHide={handleDescription} className="modal title-des-popup" id="descriptionPopup" >
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p style={{ whiteSpace: "pre-line" }}>{about}</p>

        </Modal.Body>


      </Modal>

      {/* <Header menus={menus} activeClass={"100009"} /> */}
      {localStorage.getItem("token") ? <InsideHeader activeClass={"6"} /> : <Header menus={menus} activeMenu={"partners"} activeClass={"100009"} />}
      <main id="main">

        <div className="section inner-container team media_partners" >

          {partnersData?.length > 0 && <h1 className="sec-heading mt-5"><span>PARTNERS</span></h1>}

          <div className="container">
            <div className="row team-section">
              {partnersData?.length > 0 ? partnersData?.map((eachItem, i) => {

                return (
                  <div className="col-md-2 col-sm-6 col-xs-6 sales-team">
                    <div className="team-card" >
                      {/* <img src="https://d9nwtjplhevo0.cloudfront.net/orasi/team/media-partner.png" /> */}
                      <img src={eachItem?.companyLogo?.startsWith("http") ? eachItem?.companyLogo : window?.site?.common?.imagesCDN + eachItem?.companyLogo} />
                    </div>
                    <h5>{eachItem?.companyname}</h5>
                  </div>
                )
              }) : btnLoader ? (
                <div className="orasi-preloader">
                  <img src={img+"orasi/common/images/preloader.png"} />
                </div>
              ) : <div className="no_partners">
                <div className="error-wrapper">
                  <h3>No Partners Available !</h3>
                  <img src={img+"orasi/client/resources/orasiv1/images/noprtners.svg"} className="error-img" /><br />
                  {/* <button className="fill_btn yellow-gradient push-center mt-3">BACK</button> */}
                </div>
              </div>}


            </div>
          </div>
        </div>

        {/* <div className="no_partners">
          <div className="error-wrapper">
            <h3>No Partners Available !</h3>
            <img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/error404.svg" className="error-img" /><br />
            <button className="fill_btn yellow-gradient push-center mt-3">BACK</button>
          </div>
        </div> */}


      </main>

      <Footer menus={menus} />
    </>
  );
};

export default Partners;